import React, { useEffect, useReducer, useState } from 'react';

import { Alert, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

import { NavigationFooter } from '../../../../components/NavigationFooter';
import i18n from '../../../../i18n';
import { LoadingPage } from '../../../../components/LoadingPage';
import { makeUniqueId } from '@apollo/client/utilities';

import client from '../../../../client'
import { FooterToolBar } from '../../layout';

const QUERY = gql`
  query getUpload($slug: String!) {
    importify {
      upload(slug: $slug) {
        slug
        name
        columnMappings {
          targetColumns {
            slug
            name
            columns {
              slug
              name
              selected
            }
          }
        }
      }
    }
  }
`

const MUTATION = gql`
  mutation updateUpload($name: String, $slug: String!, $columnMappings: [ImportifyColumnMappingInputType]!) {
    updateUpload(
      slug: $slug,
      name: $name,
      columnMappings: $columnMappings
    ) {
      upload {
        slug
      }
    } 
  }
`

function dispatchReducer(state, action) {
  if (action.type === 'set_column') {

    return {
      ...state,
      [action.targetColumnSlug]: action.sourceColumnSlug,
      '__nonce': makeUniqueId()
    }
  }
}

export function UploadFields({
  slug,
  data
}) {
  const [error, setError] = useState(false)

  const [name, setName] = useState('')

  const [selectedColumns, dispatchColumns] = useReducer(dispatchReducer, {})

  const navigate = useNavigate()

  const [success, setSuccess] = useState(false)

  const handleBackClicked = () => {
    navigate('../overview')
  }

  const [updateUpload, { loading: submitting }] = useMutation(MUTATION, {
    onCompleted: () => {
      setSuccess(true)
    }
  })

  useEffect(() => {
    if (data?.importify?.upload) {
      const {
        targetColumns
      } = data.importify.upload.columnMappings
      const sc = {}
      for (let targetColumn of targetColumns) {
        for(let sourceColumn of targetColumn.columns) {
          if (sourceColumn.selected) {
            dispatchColumns({
              type: 'set_column',
              targetColumnSlug: targetColumn.slug,
              sourceColumnSlug: sourceColumn.slug
            })
            
          }
        }
      } 
    }
    setName(data?.importify?.upload?.name)
  }, [data?.importify?.upload])

  const upload = data?.importify?.upload
  console.log(upload)


  const handleColumnSelect = (event) => {
    const { name, value } = event.target
    dispatchColumns({
      type: 'set_column',
      targetColumnSlug: name,
      sourceColumnSlug: value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    updateUpload({
      variables: {
        slug,
        name,
        columnMappings: Object.keys(selectedColumns).map((targetColumnId) => ({
          targetColumnId,
          sourceColumnId: selectedColumns[targetColumnId]
        }))
      }
    }).then((result) => {
      navigate(`../review`)
    })
    return false
  }

  if (upload) {
    const {
      targetColumns
    } = upload.columnMappings
    return (
      <>
        <div className="" key={selectedColumns.__nonce}>
          <div className="row">
            <div className="col-md-12">
              <form method="POST" onSubmit={handleSubmit}> 
                {error && (
                  <Alert>
                    {error}
                  </Alert>
                )}
                {success && (
                  <Alert>
                    Fields was assigned correctly!
                  </Alert>
                )}
                <p>
                  {i18n.t('This importer will import the following fields')}
                </p>
                
                <TextField
                  id="name"
                  label={i18n.t('Name')}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Enter name of import"
                />
                <table className="table" width="100%">
                  <thead>
                    <tr>
                      <th>{i18n.t('Target column')}</th>
                      <th>{i18n.t('Source column')}</th>
                      <th>{i18n.t('Type')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {targetColumns.map((targetColumn) => (
                      <tr>
                        <td>
                          <b>{targetColumn.name }</b><br />
                          <span>{targetColumn.description}</span>
                        </td>
                        <td>
                          <FormControl fullWidth>
                            <InputLabel>{i18n.t('Assign fields')}</InputLabel>
                            <Select
                              name={targetColumn.slug}
                              variant="standard"
                              value={selectedColumns[targetColumn.slug]}
                              sx={{
                                width: '100%'
                              }}
                              onChange={handleColumnSelect}
                            >
                              <MenuItem value={null}>{i18n.t('Unassigned')}</MenuItem>
                              {targetColumn.columns.map((sourceColumn) =>   ( 
                                <MenuItem value={sourceColumn.slug}>{ sourceColumn.slug }</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </table>  
                <NavigationFooter
                  saving={submitting}
                  onNextClicked={() => {}}
                  onBackClicked={handleBackClicked}
                /> 
              </form>
            </div>
          </div>
          
        </div>
      </>
    )
  }
}

UploadFieldsPage.loader = async ({ params: { slug } }) => {
  const { data } = await client.query({
    query: QUERY,
    fetchPolicy: 'network-only',
    variables: {
      slug
    }
  });
  return data
}

export function UploadFieldsPage() {
  const { slug } = useParams()
  const data = useLoaderData()
  return <UploadFields slug={slug} data={data} />
}
