import React from 'react';
import { LoginView } from '../../views/Login'; 
import { Box } from '@mui/material';

export function LoginPage({

}) {
  
  return (
    <Box sx={{position: 'fixed', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
      <LoginView />
    </Box>
  )
}

export default LoginPage;