import React from 'react';

import { Box } from '@mui/material';

import { Blurb } from '@Components/Blurb';
import { CategoryCard } from '@Components/CategoryCard';  
import { gql, useQuery } from '@apollo/client';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

const QUERY = gql`query {
  me {
    leadlists {
      slug,
      name,
      imageUrl
    }
  }
}`

export default function LibraryOverviewPage() {
  const { t } = useTranslation()
  const { data, loading, error, refetch } = useQuery(QUERY);
  return (
    <Box sx={{padding: 3}}>
      <Box sx={{display: 'grid', gap: 5, gridTemplateColumns: '1fr 1fr 1fr 1fr'}}>
        <Box
          sx={{ gridColumn: 'span 2'}}
        >
          <CategoryCard
            href={`/dashboard/queue`}
            color={`var(--primary-color)`}
            category={{
              imageUrl: '',
              name: t('your-leads'),
              color: 'var(--primary)'
            }}
          />
        </Box>
        {data?.me?.leadlists?.map(leadlist => (
          <Blurb 
            key={leadlist.slug}
            name={leadlist.name}
            srcs={leadlist?.nineEntries?.map(e => e?.imageUrl) ?? []}
            imageUrl={leadlist.imageUrl}
            href={`/dashboard/leadlist/${leadlist.slug}`}
          />
        ))}
      </Box>
    </Box>
  )
}