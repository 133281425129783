import React, { useContext, useEffect, useState } from 'react';

import { TableCell } from '@mui/material';

import { SortContext, getNodeValue } from '../../contexts/SortContext';

import { AppLayoutContext } from '../../layouts/AppLayout';
import { Check } from '@mui/icons-material';

export function TableHeaderCell({
  field,
  children,
  ...props
}) {
  const [filteredValues, setFilteredValues] = useState([])
  const {
    filters,
    sortBy,
    setSortBy,
    setSortByDirection,
    sortByDirection,
    nodes,
    setFilters
  } = useContext(SortContext)

  useEffect(() => {
    nodes.forEach(row => {
      const value = getNodeValue({ node: row, column: { id: field} })
      if (filteredValues.find(filteredValue => filteredValue === value)) {
      } else {
        setFilteredValues([
          ...filteredValues,
          value
        ])
      }
    })
  }, [nodes])
  
  const {
    setContextMenu,
    contextMenu
  } = useContext(AppLayoutContext)
  return (
    <th
      style={{ padding: 10, textAlign: field.headerTextAlign, width: field.width }}
      /*onContextMenu={(event) => {
        event.preventDefault() 
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
                menuItems: filteredValues.map(value => {
                  return {
                    label: value,
                    Icon: filters[field]?.find(filter => filter === value) ? Check : () => null,
                    onClick: () => {
                      const newFilters = {
                        ...filters,
                      }
                      if (newFilters[field]?.find(filter => filter === value)) {
                        newFilters[field] = newFilters[field].filter(filter => filter !== value)
                      } else {
                        newFilters[field] = [
                          ...newFilters[field] || [],
                          value
                        ]
                      }
                      setContextMenu(null)
                      setFilters(newFilters)
                    }
                  }
                })
              }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
              // Other native context menus might behave different.
              // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
              null,
        );
      }}*/
      {...props}
    >
      <button
        onContextMenu={() => {

        }}
        onClick={() => {
          if (sortBy === field) {
            setSortByDirection(sortByDirection === 'desc' ? 'asc' : 'desc')
          } else {
            setSortBy(field)
            setSortByDirection(null)
          }
        }}
        
        style={{ border: 'none', background: 'none'}}
      >
        {children}
        {sortBy === field && sortByDirection === 'asc' && '▲'}
        {sortBy === field && sortByDirection === 'desc' && '▼'}
      </button>     
    </th>
  )
}