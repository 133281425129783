import React from 'react';

import { gql, useMutation, useQuery } from '@apollo/client'
import { Box, Container } from '@mui/system';
import {CircularProgress, Typography} from '@mui/material';

import i18n from '../../../i18n';

import { NotificationCard } from '../../../components/NotificationCard';
import { useTranslation } from 'react-i18next';

const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationAsRead($slug: String!) {
    markNotificationAsRead(slug: $slug) {
      notificationRead {
        slug
      }
    }
  }
`

const MARK_NOTIFICATION_AS_UNREAD = gql`
  mutation markNotificationAsUnread($slug: String!) {
    markNotificationAsUnread(slug: $slug) {
      ok
    }
  }
`

const QUERY = gql`
  query {
    notify {
      notifications {
        slug
        text
        subject {
          username
          imageUrl
        }
        isRead
        predicate,
        fieldId
        value
        created
        indirectNode {
          slug
          name
          text
          type
        }
        node {
          slug
          name
          text
          type
        }
      }
    }
  }
`

export function Notifications() {
  const { t } = useTranslation()
  const { data, refetch, loading, error } = useQuery(QUERY)

  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ, {
    onCompleted: () => {
      refetch()
    }
  })
  const [markNotificationAsUnread] = useMutation(MARK_NOTIFICATION_AS_UNREAD, {
    onCompleted: () => {
      refetch()
    }
  })

  return (
    <Container>
      <Typography variant="h3">{i18n.t('Notifications')}</Typography>
      <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
        {error ?
            <span>An error occurred</span>
        : loading ?
          <CircularProgress />
          :
        data?.notify?.notifications?.length > 0 ?
        data?.notify?.notifications.map(notification => (
          <NotificationCard 
            onToggleReadClick={() => {
              if (notification.isRead) {
                markNotificationAsUnread({
                  variables: {
                    slug: notification.slug
                  }
                }).then(() => {

                })
              } else {
                markNotificationAsRead({
                  variables: {
                    slug: notification.slug
                  }
                }).then(() => {

                })
              }
            }}
            {...notification} 
          /> 
        )) :
          <Typography variant="h2">{i18n.t('No messages found')}</Typography>
        }
      </Box>
    </Container>
  )
}

export function NotificationsPage() {
  return (
    <Notifications />
  )
}

export default NotificationsPage