import React, { useEffect } from 'react';

import { Box, CircularProgress, TextField } from '@mui/material';

import { gql, useMutation, useQuery } from '@apollo/client'

import { LoadingButton } from '@mui/lab';

import i18n from '../../i18n'
import { useTranslation } from 'react-i18next';

const QUERY = gql`
  query getLeadlist($slug: String!) {
    leadlist(slug: $slug) {
      
      name
      slug    
      ...LeadlistFields
    }
  }
`

const MUTATION = gql`mutation editLeadlist($slug: String!, $name: String!) {
  editLeadlist(slug: $slug, name: $name) {
    leadlist {
      name
      slug
    }
  }
}`

export function EditLeadlist({
  slug,
  onClose
}) {
  const { t } = useTranslation()
  const [name, setName] = React.useState('')
  const [editLeadlist, { loading: editing }] = useMutation(MUTATION, {
    onCompleted: (data) => {
      onClose(data?.leadlist)
    }
  })

  const { data, loading } = useQuery(QUERY, {
    variables: {
      slug
    }
  })

  useEffect(() => {
    if (data?.leadlist) {
      setName(data?.leadlist?.name)
    }
  }, [data])

  const handleSubmitEditLeadListDialogForm = (event) => {
    event.preventDefault()
    editLeadlist({
      variables: {
        slug,
        name
      }
    })
  }

  if (loading) {
    return (
      <CircularProgress />
    )
  }

  return (
    <form onSubmit={handleSubmitEditLeadListDialogForm}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          label="name"
          name="name"
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <LoadingButton
          loading={editing} 
          variant="contained"
          color="primary"
          type="submit"
        >
          {i18n.t('Save')}
        </LoadingButton>
      </Box>
    </form>
  )
}