import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { GenericHeader } from '@Components/GenericHeader';
import { Page } from '@Components/Page';
import i18n from '@I18n';
import { useTranslation } from 'react-i18next';

const QUERY = gql`
  query getPlaylist($slug: String!) {
    moodler {
      playlist(slug: $slug) {
        name
        slug
      }
    }
  }
`

export function PlaylistLayoutView({
  slug,
  children
}) {
  const { t } = useTranslation()
  
  const { data, refetch } = useQuery(QUERY, {
    variables: {
      slug
    }
  })

  return (
    <Page>
      {data?.moodler?.playlist && <GenericHeader
        imageSrcs={data?.moodler?.playlist.imageUrl}
        {...data?.moodler?.playlist}
        
        status={'success'}
        subheading={i18n.t('playlist')}
      />}
      {children}       
    </Page>
  ) 
}

export default function PlaylistLayout() {
  const { slug } = useParams()
  return (
    <PlaylistLayoutView slug={slug}>
      <Outlet />
    </PlaylistLayoutView>
  )
}