import { Box, Button, Container } from '@mui/material';
import React from 'react';
import i18n from '../../i18n';
import { Blurb } from '../Blurb';
import { useNavigate } from 'react-router';


export const Section = ({ 
  nodes,
  name,
  href,
  children,
  baseUrl='',
  renderBlurbContent = (node) => { return <></>}
}) => {
  const navigate = useNavigate()
  return (
    <div style={{marginTop: 15}}>
      <Container className="container"> 
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
          <Box sx={{flexDirection: 'column', gap: 1, flex: 1}}>
            <h2>{i18n.t(name)}</h2>
          </Box>
          {href && <Button color="inherit" onClick={() => navigate(`${baseUrl}${href}`).then(() => {
          
        })}>{i18n.t('Show all')}</Button>}
        </Box>
      </Container>
      <div className="section-row">
        <Container>
          {(nodes?.map(artist => (
            <Blurb key={artist.slug} imageUrl={artist.imageUrl} name={artist.name} icon="" isRounded={true} href={`${baseUrl}artist/${artist.slug}`}>{renderBlurbContent(artist)}</Blurb>
          )))}
          {children}
        </Container>
      </div>
    </div>
  )
}