import { Box } from '@mui/material';
import React from 'react';

export function Content({
  children,
  ...props
}) {
  return (
    <Box {...props}>{children}</Box>
  )
}