import React from 'react';

export function Backdrop({
  children,
  src,
  ...props
}) {
  return (
    <div className="backdrop" style={{ '--background': `url(${src})`}} {...props}>{children}</div>
  )
}