import React from 'react';

import { gql } from '@apollo/client';
 
import { MusicNote } from '@mui/icons-material';

import { useQuery } from '@apollo/client'

import { Container, FormControl, Select, InputLabel, MenuItem, Button, CircularProgress, Box } from '@mui/material';

import { ReleaseAndCPDatesInSyncStatus } from '@/components/ReleaseAndCPDatesInSyncStatus'
import { FlaggedStatus } from '@/components/FlaggedStatus';

import { Warning } from '@mui/icons-material';
import { Check } from '@mui/icons-material';

import { useLoaderData } from 'react-router-dom';

import { NodeTable } from '@/components/NodeTable';

import client from '@/client';
import i18n from '@/i18n';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { CheckCircle, XCircle } from 'phosphor-react';

import { Link } from '@/components';

const QUERY = gql`
  query getFingerprints($q: String) {
    screening {
      organizations(q: $q) {
        id
        name
        updated
      }
    }
  }
`

export default function OrganizationsIndexPage() {
  return (
    <Box>
      <h1>Select an organization to the left</h1>
    </Box>
  )
}

export function OrganizationsPage() {
  return ( 
    <OrganizationsView /> 
  )
} 

const renderWarningIcon = ({ node }) => {
  const fingerprint = JSON.parse(node.fingerprint)
  if (fingerprint.length > 0) {
    return (
      <Warning style={{ color: '#ffaa00'}}  />
    )
  } else {
    return (
      <Check style={{ color: "#008800" }} />
    )
  }
}

export function OrganizationsView() {

  const { data, loading } = useQuery(QUERY, {
    variables: {
    }
  })

  const onNodesChecked = () => {}
  const onPerformAction = () => {}

  if (loading) {
    return <CircularProgress />
  }

  if (data?.screening?.organizations) {
    const screening = data?.screening
    const organizations = screening?.organizations
    return (
      <NodeTable
        onNodesChecked={onNodesChecked}
        onPerformAction={onPerformAction}
        canCheck={true}  
        renderRowClassNames={({ node }) => {    
          return !node.uncopyrighted || !node.notMatchesArtistWatchlist || !node.originalReleaseDateMatchesCopyrightYears || !node.originalReleaseDateIsNotDifferentFromCopyrightAndPhonographicYear ? 'row-warning' : ''
        }}
        emptyHeading={i18n.t('No flagged organizations found')}
        emptyText={i18n.t('No flagged organizations found')}
        emptyIcon={MusicNote}
  
        isRowUnavailable={(row) => {
          return false        
        }}
        toolbarButtons={() => <></>}
        sx={{
          '& .row-success': {
            bgcolor: 'rgba(0, 255, 0, .1)'
          },
          '& .row-error': {
            bgcolor: 'rgba(255, 0, 0, .1)'
          },
          '& .row-warning': {
            bgcolor: 'rgba(255, 255, 0, .1)'
          }
        }}
        actions={[ 
        ]}
        columns={[
          {
            id: 'name',
            name: i18n.t('name'),
            type: 'name',
            render: ({ node }) => {
              return (
                <Link to={`/dashboard/screening/organizations/${node.id}`}>{node.name}</Link>
              )
            }
          }, 
          {
            id: 'view',
            name: i18n.t('created'),
            type: 'text',
            render: ({ node }) => { 
              return (
                <Button 
                  onClick={(event) => {
                    event.preventDefault()
                    window.location.href = `/dashboard/screening/organizations/${node.id}`
                    return false
                  }}
                >{
                  moment(node.created).format('YY-MM-DD')
                }</Button>
              )
            }
          }
        ]}
        nodes={organizations}
      /> 
    )
  } else {
    return (
      <p>Error</p>
    )
  }
}
