import React, { useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import DropZone from '../../../components/DropZone';
import i18n from '../../../i18n';
import { uploadFiles } from '../../../actions/login';
import { useNavigate } from 'react-router-dom';

const QUERY = gql`
  query {
    importify {
      uploads {
        slug
        name
      }
    }
  }
`

export function ImportOverviewPage() {
  const { data, loading, error } = useQuery(QUERY)

  const navigate = useNavigate()

  const [files, setFiles] = useState([])

  const [uploadedFiles, setUploadedFiles] = useState([])

  const handleFilesUploaded = (files) => {

  }

  const handleUploadFiles = (files) => {
    uploadFiles(files).then((upload) => {
      navigate(`/dashboard/upload/${upload.slug}`)
    })
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12"> 
            <DropZone
              accept={"text/csv"}
              onFilesUploaded={handleFilesUploaded}
              onUploadFiles={handleUploadFiles}
              defaultUploadedFiles={uploadedFiles}
              language={i18n.language}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ImportOverviewPage