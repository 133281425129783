import React from "react"

import { NodeTable } from "../NodeTable"
import i18n from "../../i18n"
import { MusicNote } from "phosphor-react"

export function Playlist({
  entries,
  onPerformAction,
  onNodesChecked,
  onSortByChanged,
  defaultSortByFieldId,
  defaultSortByDirection = 'asc'
}) {
  return ( 
    <NodeTable
      getRealNode={(node) => node.node}
      onNodesChecked={onNodesChecked}
      onPerformAction={onPerformAction}
      canCheck={true} 
      defaultSortByDirection={defaultSortByDirection}
      defaultSortByFieldId={defaultSortByFieldId}
      onSortByChanged={onSortByChanged}
      renderRowClassNames={({ node }) => {  
        return `row-${node?.node?.streamingQuality}`
      }}
      emptyHeading={i18n.t('No tracks found')}
      emptyText={i18n.t('No tracks found')}
      emptyIcon={MusicNote}

      isRowUnavailable={(row) => row.label.isBlacklisted}
      toolbarButtons={() => <></>}
      sx={{
        '& .row-success': {
          bgcolor: 'rgba(0, 255, 0, .1)'
        },
        '& .row-error': {
          bgcolor: 'rgba(255, 0, 0, .1)'
        },
        '& .row-warning': {
          bgcolor: 'rgba(255, 255, 0, .1)'
        }
      }}
      actions={[ 
      ]}
      columns={[
          {
            id: 'number',
            name: i18n.t('number'),
            getCellValue: (proxyNode, column) => (proxyNode[column.id] ?? 0) + 1
          },
          {
            id: 'track',
            name: i18n.t('player'),
            type: 'player'
          }, 
          {
            id: 'name',
            type: 'string',
            name: i18n.t('name'),
            render: ({ node }) => {
              return node.node.name
            }
          },
          {
            id: "artists",
            name: i18n.t("Artists"),
            
            render: ({ node }) => {
              return node?.node?.artists.map(
                a => <span>{a.name}</span>
              )
            }
          },
          {
            id: 'streams',
            type: 'string',
            name: i18n.t('Streams'),
            render: ({ node }) => {
              return node?.node?.streams
            }
          },
          {
            id: 'skips',
            type: 'string',
            name: i18n.t('Skips'),
            render: ({ node }) => {
              return node?.node?.skips
            }
          },
          {
            id: 'streamingQuality',
            type: 'string',
            name: i18n.t('Streaming quality'),
            render: ({ node }) => {
              return node?.node?.streamingQuality
            }
          }
      ]}
      nodes={entries}
    /> 
  )
}
