import React, {useContext, useState} from 'react';
import {Box, Container, TextField} from "@mui/material";
import {gql, useMutation, useQuery} from "@apollo/client";

import {useNavigate} from "react-router-dom";
import i18n from "../../../i18n";

import {NavigationFooter} from '../../../components/NavigationFooter';

import {NodeTable} from '../../../components/NodeTable';

import {AppContext} from '../../../contexts/AppContext';
import {LeadlistView} from "../leadlist/overview";
import {LoadingPage} from "../../../components/LoadingPage";

const MUTATION = gql`
  mutation createLeadsFromExternalArtistUrls($urls: [String]) {
    createLeadsFromExternalArtistUrls(urls: $urls) {
      upload {
        slug
        leadlists {
          slug
        }
      }
    }
  }
`

const ASSIGN_MUTATION = gql`
    mutation assignLeadlist($slug: String!, $username: String) {
        assignLeadlist(slug: $slug, username: $username) {
            leadlist {
                slug
            }
        }
    }
`

export function CreateLeadPage() {
  const navigate = useNavigate()
  const handleLeadCreated = (lead) => {
    navigate(`/dashboard/queue`)
  }
  return (
    <Container>
      <CreateLeadView onLeadCreated={handleLeadCreated} standalone={true}/>
    </Container>
  )
}

export function CreateLeadView({
  onLeadCreated = (lead) => {
  },
  standalone = false
}) {
  const [leadlist, setLeadlist] = useState(null)
  const [urls, setUrls] = useState([])
  const [loading, setLoading] = useState(false)

  const [createLeadsFromExternalArtistUrls, {
    data: submitData,
    loading: submitting,
    error: submitError
  }] = useMutation(MUTATION)

  const [assignLeadlist, {
    data: assignData,
    loading: assigning,
    error: assignError
  }] = useMutation(ASSIGN_MUTATION)

  const {addProcess} = useContext(AppContext)

  const handleSubmit = (event) => {
    event.preventDefault()
    assignLeadlist({
      variables: {
        slug: leadlist.slug
      }
    }).then(({data: {lead}}) => {
      onLeadCreated(lead)
    })
    return false;
  }

  const handleInput = (event) => {
    const html = event.target.innerHTML
    const regex = /((https?:\/\/open\.spotify\.com)|(spotify))([\/:])(artist)([\/:])([a-z0-9]+)/gi;
    const spotifyUrls = []
    html.replace(regex, (url) => {
      if (spotifyUrls.indexOf(url) === -1) {
        spotifyUrls.push(url)
      }
    })
    if (spotifyUrls.length > 10) {
      alert("Max 10 Spotify URLs at the same time")
      return
    }
    if (spotifyUrls.length < 1) {
      return
    }
    setLoading(true)
    addProcess(
      createLeadsFromExternalArtistUrls({
        variables: {
          urls: spotifyUrls.slice(0, 5)
        }
      })
    ).then(({data: { createLeadsFromExternalArtistUrls: { upload: { leadlists }}}}) => {
      setLeadlist(leadlists[0])
      setLoading(false)
    })
  }

  const handlePaste = (event) => {
    setTimeout(() => {
      handleInput(event)
    }, 100)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box className="linksGrid">
          <Box>
            <div contentEditable
              className={"linkboard"}
              name={'urls'}
              onPaste={handlePaste}
              placeholder="Paste a list of Spotify URLs (Eg. from Google Sheets) and it will lookup the artists"
              variant="standard"
              label={i18n.t()}
            />
          </Box>

          {loading ? <LoadingPage /> : leadlist ? <LeadlistView displayColumns={['player', 'name', 'monthlyListeners', 'contractValueInEur']} slug={leadlist?.slug} /> : <Box />}
        </Box>
        <NavigationFooter
          finishLabel={"Import leads"}
          saving={assigning}
          onFinishClicked={() => {
          }}
        />
      </form>
    </>
  )
}