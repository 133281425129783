import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { LeadlistEntryTable } from '../../../../components/LeadlistEntryTable';
import { Box, CircularProgress, Typography } from '@mui/material';

import i18n from '../../../../i18n';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const QUERY = gql`
  query search($q: String!) {
    search(q: $q) {
      leads {
        entries {
          slug,
          lead {
            ...LeadFields
          }
        }
      }
      leadlists {
        ...LeadlistFields
      }
    }
  }
`

export function SearchResultOverview({
  q
}) {
  const { t } = useTranslation()
  const { data, loading } = useQuery(QUERY, {
    variables: {
      q
    }
  })
  if (loading) {
    return <CircularProgress />
  }
  return (
    <Box sx={{ padding: 1 }}> 
      {data?.search?.leads?.entries?.length > 0 && (
        <>
          <Typography variant="h4">{i18n.t('Leads')}</Typography>
          <LeadlistEntryTable
            entries={
              data?.search?.leads?.entries
            }
          />
        </>
      )} 
    </Box>
  )
}

export default function SearchResultOverviewPage() {
  const { q } = useParams()
  return (
    <SearchResultOverview q={q} />
  )
}