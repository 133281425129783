import React from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as CloseSidebarSVG } from '../images/CloseSidebar.svg';

export function CloseSidebar({
  ...props
}) {
  return (
    <SvgIcon component={CloseSidebarSVG} {...props} inheritViewBox />
  )
}
