import React from 'react';

import { Link } from '../Link'

export const CategoryCard = ({
  category,
  href,
  color = 'currentColor',
  aspectRatio = '16/9'
}) => {
  return (
    <Link href={href}>
      <div className={"card card-category"} key={category.id} style={{ aspectRatio, '--card-color': color, '--card-background': category.backgroundColorHex ?? category.color}}>
        <div style={{left: 0, top: 0, width: '100%', height: '100%', position: 'relative', overflow: 'hidden'}}>
          <div style={{position: 'absolute', right: 0, bottom: 0, width: '33%', aspectRatio: '1/1', transform: 'rotate(20deg)', backgroundSize: 'contain', backgroundImage: 'url(' + category.imageUrl + ')'}}>

          </div>
        </div>
        <div className="card-title" style={{padding: 30, fontSize: '5rem'}}>
          {category.name}
        </div>
      </div>
    </Link>
  )
}