import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';

import { Page } from '@Components/Page';  
import { Entry } from '@Components/Entry';
import i18n from '@I18n';
import {Box, CircularProgress, Container, Grid, Typography} from '@mui/material';
import { Section } from '@Components/Section';
import { AppLayoutContext } from '@Layouts/AppLayout';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorView } from '@Components/ErrorView';
import { LeadBlurb } from '../../components/LeadBlurb';
import { gql, useMutation, useQuery } from '@apollo/client';
import { NotificationCard } from '../../components/NotificationCard';
import { useTranslation } from 'react-i18next';

const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationAsRead($slug: String!) {
    markNotificationAsRead(slug: $slug) {
      notificationRead {
        slug
      }
    }
  }
`

const MARK_NOTIFICATION_AS_UNREAD = gql`
  mutation markNotificationAsUnread($slug: String!) {
    markNotificationAsUnread(slug: $slug) {
      ok
    }
  }
`

const START_PAGE_QUERY = gql`query {
  notify {
    notifications {
      slug
      isRead
      created
      subject {
        username
        imageUrl
      }
      predicate,
      fieldId
      value
      indirectNode {
        slug
        name
        text
        type
      }
      node {
        slug
        name
        text
        type
      }
    }
  }
  leads {
    latestLeadlist {
      slug
      objects {
        entries {
          slug
          lead {
            ...LeadFields
          }
        }
      }
    }
    me {
      leads {
        ...LeadFields
      }
    }
  }
}`

const OverviewPage = () => {
  const { t } = useTranslation()
  console.log("Rendering overview page")
  const [greeting, setGreeting] = useState('');
  const { data, loading, error, refetch } = useQuery(START_PAGE_QUERY)

  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ, {
    onCompleted: () => {
      refetch()
    }
  })
  const [markNotificationAsUnread] = useMutation(MARK_NOTIFICATION_AS_UNREAD, {
    onCompleted: () => {
      refetch()
    }
  })

  const {
    setNavigating,
  } = useContext(AppLayoutContext)
  useEffect(() => { 
    setNavigating(false)
    const now = moment()

    if (now.hour() > 0 && now.hour() <= 6) {
       setGreeting('Good Night!')
    } else if (now.hour() >= 7 && now.hour() <= 10) {
      setGreeting('Good morning!')
    } else if (now.hour() >= 11 && now.hour() <= 12) {
      setGreeting('Good lunch!')
    } else if (now.hour() >= 13 && now.hour() <= 17) {
      setGreeting('Good afternoon!')
    } else if (now.hour() >= 17 && now.hour() <= 23) {
      setGreeting('Good evening!')
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const notifications = data?.notify?.notifications ?? []
  const leads = data?.leads?.me?.leads
  const latestLeadlist = data?.leads?.latestLeadlist

  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      <Page>
        <Container>
          <div className="row">
            <div className="col-md-12">
              <br />
              <h1>{ greeting }</h1>
              <br />
              <Box sx={{display: 'grid', gap: 1, gridTemplateColumns: '1fr 1fr'}}>
                <Entry href={`/dashboard/discover`} name='Discover leads' />
                <Entry href={`/dashboard/queue`} name='Your leads' />
                <Entry href={`/dashboard/labels`} name='Labels' />
                { leads?.length > 0 &&
                  leads.slice(0, 3).map(lead => (
                  <Entry
                    key={lead.slug}
                    href={`/dashboard/lead/${lead.slug}`}
                    name={lead.name}
                  />
                ))}
              </Box>
            </div>
          </div>
        </Container>
        <Container>
          <Section
            name={i18n.t('Notifications')}
            href={`notifications`}
            baseUrl={`/dashboard/`}
          >
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
            {error ?
              <p>There was an error loading</p>
            : loading ?
              <CircularProgress />
            :
              notifications?.length > 0 ?
            notifications.slice(0, 3).map(notification => (
              <NotificationCard 
                onToggleReadClick={() => {
                  if (notification.isRead) {
                    markNotificationAsUnread({
                      variables: {
                        slug: notification.slug
                      }
                    }).then(() => {
                      refetch().then(() => {})
                    })
                  } else {
                    markNotificationAsRead({
                      variables: {
                        slug: notification.slug
                      }
                    }).then(() => {
                      refetch().then(() => {})
                    })
                  }
                }}
                {...notification} 
              /> 
            )) :
              <Typography variant="h2">{i18n.t('No messages found')}</Typography>
            }
            </Box>
          </Section>
        </Container>
        {leads?.length > 0 &&
          <Section
            href={`queue`}
            baseUrl={`/dashboard/`}
            name={i18n.t("Artists to contact")}
            nodes={leads}
          >
            {leads?.map(lead => (
              <LeadBlurb {...lead} />
            ))}
          </Section>
        }
        {latestLeadlist?.objects?.entries?.length > 0 &&
          <Section
            name={i18n.t('Discover new leads')}
            href={`discover`}
            baseUrl={`/dashboard/`}
          >
            {latestLeadlist?.objects?.entries?.map(entry => (
              <LeadBlurb {...entry.lead} />
            ))}
          </Section>
        }
      </Page>
    </ErrorBoundary>
  )
}

export default OverviewPage