import React from 'react';

import moment from 'moment';

import { Link } from '../Link';
import { Typography } from '@mui/material';

export function NodeView({
  value,
  baseUrl
}) {
  // deepcode ignore UseNumberIsNan: <please specify a reason of ignoring this>
  if (!isNaN(value)) {
    return (
      <Typography color="default">{value?.toLocaleString('en-US')}</Typography>
    )
  } else if (value instanceof Array) {
    return (
      <>
        {value.map((v, i) => (
          <>{i > 0 ? ', ' : ''}<NodeView value={v} /></>
        ))}
      </>
    )
  } else if(value instanceof Date) {
    return (
      <Typography>{moment(value).format('YYYY-MM-DD')}</Typography>
    )
  } else if(value instanceof Object) {
    return (
      <Link href={`${baseUrl}${value.type}/${value.slug}`}>
        <Typography>{value.name ?? value.slug}</Typography>
      </Link>
    )
  } else {
    return <Typography color="primary">{value}</Typography>
  }
}