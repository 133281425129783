import React from 'react';

import { NavLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Tabs } from '@mui/material';
import { NavTabBarItem, TabBar, TabBarItem } from '@Components/TabBar';
import i18n from '../../../i18n';

export function LibraryLayout({
  children
}) {
  const { t } = useTranslation()
  return (
    <Box>
      <TabBar>
        <NavTabBarItem 
          to="/dashboard/library"
          component={NavLink}
        >{i18n.t('Overview')}</NavTabBarItem>
        <NavTabBarItem 
          to="/dashboard/library/playlists"
          component={NavLink}
        >{i18n.t('Playlists')}</NavTabBarItem>
      </TabBar>
      <Box style={{flex: 1}}>
        <Outlet />
      </Box>
    </Box>
  )
}