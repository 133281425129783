export const LEAD_STATUS_CODES = [
  {
    id: -1,
    slug: "unqualified",
    name: "Unqualified",
    color: "#888888"
  },
  {
    id: 0,
    slug: "new",
    name: "New",
    color: "##FFE775"
  },
  {
    id: 1,
    slug: "open",
    name: "Open",
    color: "#FCFF73"
  },
  {
    id:2,
    slug: "contact",
    name: "Contact",
    color: "#F89276"
  },
  {
    id: 3,
    slug: "connected",
    name: "Connected",
    color: "#ECEA22"
  },
  {
    id: 4,
    slug: "progress",
    name: "Progress",
    color: "#C0FF6E"
  },
  {
    id: 5,
    slug: "opportunity",
    name: "Opportunity",
    color: "#00FFFE"
  },
  {
    id: 6,
    slug: "customer",
    name: "Customer",
    color: "#44FF5F"
  }
]
