import React from 'react';

import { Link } from '../../../Link';

import { TabBarItem } from '..';
 
import { useLocation } from 'react-router';

export function NavTabBarItem({ 
  children,
  Component="div",
  to='',
  ...props
}) { 

  let pathname = ''
  if (typeof window !== 'undefined') {
    pathname = window.location.pathname 
  }

  const toPathname = to.indexOf('http') === 0 ? to : to.indexOf('/') === 0 ? to : `${pathname.split(/\//g).slice(0, pathname.split(/\//g).length - 1).join('/')}/${to}`
  
  const active = pathname.indexOf(toPathname) === 0

  return (
    <TabBarItem to={toPathname} Component={Link} active={active} {...props}>
     {children}
    </TabBarItem>
  )
}