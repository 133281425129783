import { CaretDown, CaretUp, Circle } from 'phosphor-react';
import React from 'react';


export function ChangeView({
  value,
  unit
}) {
  return (
    <span className="change-view">
      {value === 0 ?
        <span className="info"><Circle /> 0</span>
      : value > 0 ?
        <span className="success"><CaretUp /> {value?.toLocaleString('en-US')}{unit}</span>
      : value < 0 ?
        <span className="error"><CaretDown />{value?.toLocaleString('en-US')}{unit} </span>
      : <></>}
    </span>
  )
}