import React from 'react'

import { useTranslation } from 'react-i18next';

import { Outlet } from 'react-router-dom'
import i18n from '../../../i18n';


export function QueueLayout() {
  const { t } = useTranslation()
  return (
    <> 
      <h1>{i18n.t('Queue')}</h1>
      <Outlet />
    </>
  )
}

export default QueueLayout
