import React from 'react';

import cx from 'classnames';

import styles from './index.module.scss';

export { NavTabBarItem } from './NavTabBarItem'


export function TabBarItem({
  active=false,
  children,
  Component="div",
  ...props
}) {
  return (
    <Component className={cx(styles.tabBarItem, active && styles.tabBarItemActive)} {...props}>
      {children}
      <div className={styles.tabBarItemUnderline} />
    </Component>
  )
}