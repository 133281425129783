import React from 'react'
import { Image } from '../Image';
import { Link } from '../Link';

export const Blurb = ({
  href,
  name,
  isRounded,
  icon,
  imageUrl,
  srcs,
  children
}) => (
  <Link href={href}> 
    <div className="blurb">
      <Image srcs={srcs} src={imageUrl} name={name} isRounded={isRounded} icon={icon} />
      <div className="blurb-content">
        <h5>{name}</h5>
        {children}
      </div>
    </div> 
  </Link> 
)