import React from 'react';

import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

export function LogoutPage() {
  const { t } = useTranslation()
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', aspectRatio: '16/9'}}>
      <h3>{i18n.t('You have been logged out')}</h3>
      <p>{i18n.t('Please close this window')}</p>
    </div>
  )
}