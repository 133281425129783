import React, { useContext, useState } from 'react';

import { PlayerContext } from '../Player';
import { IconButton } from '@mui/material';
import { PauseCircleFilled, PlayCircleFilled } from '@mui/icons-material';

export function TrackPlayButton({
  value,
  size=32,
  sticky=false,
  node,
  color,
  ...props
}) {
  const {
    track,
    loadTrack,
    togglePlayPause,
    status
  } = useContext(PlayerContext)
    const nodeTrack = value
    return (
      <div className={`td-player ${sticky && 'td-player-sticky'} ${nodeTrack && track && nodeTrack?.previewUrl === track?.previewUrl ? 'td-player-playing' : ''}`} {...props}>
        <IconButton
          color={color}
          onClick={() => { 
            if (track && track.previewUrl && nodeTrack.previewUrl === track.previewUrl) {
              togglePlayPause()
            } else {
              const newTrack = {...nodeTrack}
              if (!newTrack.artists) {
                newTrack.artists = [{
                  name: node.name,
                  imageUrl: node.imageUrl,
                  type: 'artist',
                  slug: node.slug
                }]
                if (node.imageUrl) {
                  newTrack.imageUrl = node.imageUrl
                }

              }
              loadTrack(newTrack)
            }
          }}
        >
          {value && track && (track.previewUrl === value.previewUrl) && status === 'playing' ? (
            <PauseCircleFilled style={{color, fontSize: size}} />) : <PlayCircleFilled style={{color, fontSize: size}} />}
        </IconButton>
      </div>
    )
}