import React from 'react';

import { gql } from '@apollo/client';
 
import { MusicNote } from '@mui/icons-material';

import { Container, FormControl, Select, InputLabel, MenuItem } from '@mui/material';

import { ReleaseAndCPDatesInSyncStatus } from '@/components/ReleaseAndCPDatesInSyncStatus'
import { FlaggedStatus } from '@/components/FlaggedStatus';

import { Warning } from '@mui/icons-material';
import { Check } from '@mui/icons-material';

import { useLoaderData } from 'react-router-dom';

import { NodeTable } from '@/components/NodeTable';

import client from '@/client';
import i18n from '@/i18n';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { CheckCircle, XCircle } from 'phosphor-react';

import { Link } from '@/components';

const QUERY = gql`
  query getFingerprints(
    $organizationId: ID!,
    $copyrighted: String,
    $hasFingerprint: String,
    $matchesArtistWatchlist: String,
    $mismatchReleaseAndCopyrightYears: String
  ) {
    screening {
      organization(
        organizationId: $organizationId
      ) {
        assetFingerprints(
          copyrighted: $copyrighted,
          hasFingerprint: $hasFingerprint,
          matchesArtistWatchlist: $matchesArtistWatchlist
          mismatchReleaseAndCopyrightYears: $mismatchReleaseAndCopyrightYears
        ) {
          id
          name
          isrc
          uncopyrighted
          previewUrl
          created
          originalReleaseDateIsNotDifferentFromCopyrightAndPhonographicYear
          originalReleaseDate
          cLineYear
          cLineText
          pLineYear
          pLineText
          fingerprint
          notMatchesArtistWatchlist
          originalReleaseDateMatchesCopyrightYears
          productFingerprint {
            id
            name
            upc
            submitted
            originalReleaseDate
            cLineYear
            cLineText
            pLineYear
            pLineText
            originalReleaseDateMatchesCopyrightYears
            notMatchesArtistWatchlist
          }
        }
      }
    }
  }
`

export function OrganizationSubmissionsPage() {    
  const data = useLoaderData()
  const [searchParams, setSearchParams] = useSearchParams()
  const uncopyrighted = searchParams.get('uncopyrighted') != "null" ? searchParams.get('uncopyrighted') : null
  const hasFingerprint = searchParams.get('hasFingerprint') != "null" ? searchParams.get('hasFingerprint') : null
  const matchesArtistWatchlist = searchParams.get('matchesArtistWatchlist') != "null" ? searchParams.get('matchesArtistWatchlist') : null
  const mismatchReleaseAndCopyrightYears = searchParams.get('misatchReleaseAndCopyrightYears') != "null" ? searchParams.get('mismatchReleaseAndCopyrightYears') : null

  if (!data) {
    return <>No data</>
  }

  const organization = data.organization;
  if (!organization) {
    return <></>
  }

  return (
    <div>
      <h1>{i18n.t('submissions-for-organization')} {organization.name}</h1>
      <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', gap: 10 }}>
        <FormControl sx={{ minWidth: 220 }}>
          <InputLabel>{i18n.t('content-status')}</InputLabel>
          <Select
            value={uncopyrighted}
            onChange={(event) => {
              setSearchParams(params => {
                params.set('copyrighted', event.target.value)
                return params
              })
            }}
          > 
            <MenuItem value={null}>{i18n.t('no-filter')}</MenuItem>
            <MenuItem value={"safe"}>{i18n.t('safe')}</MenuItem>      
            <MenuItem value={"copyright"}>{i18n.t('copyrighted')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 220 }}>
          <InputLabel>{i18n.t('has-fingerprint')}</InputLabel>
          <Select
            value={hasFingerprint}
            onChange={(event) => {
              setSearchParams(params => {
                params.set('hasFingerprint', event.target.value)
                return params
              })
            }}
          > 
            <MenuItem value={null}>{i18n.t('no-filter')}</MenuItem>     
            <MenuItem value={"safe"}>{i18n.t('safe')}</MenuItem>      
            <MenuItem value={"flagged"}>{i18n.t('flagged-content')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 220 }}>
          <InputLabel>{i18n.t('matches-artist-watchlist')}</InputLabel>
          <Select
            value={matchesArtistWatchlist}
            onChange={(event) => {
              setSearchParams(params => {
                params.set('matchesArtistWatchlist', event.target.value)
                return params
              })
            }}
          > 
          <MenuItem value={null}>{i18n.t('no-filter')}</MenuItem>   
            <MenuItem value={"safe"}>{i18n.t('safe')}</MenuItem>      
            <MenuItem value={"flagged"}>{i18n.t('matches-artist-watchlist')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 220 }}>
          <InputLabel>{i18n.t('mismatch-release-and-copyright-year')}</InputLabel>
          <Select
            value={mismatchReleaseAndCopyrightYears}
            onChange={(event) => {
              setSearchParams(params => {
                params.set('mismatchReleaseAndCopyrightYears', event.target.value)
                return params
              })
            }}
          > 
          <MenuItem value={null}>{i18n.t('no-filter')}</MenuItem>   
            <MenuItem value={"safe"}>{i18n.t('safe')}</MenuItem>      
            <MenuItem value={"flagged"}>{i18n.t('matches-artist-watchlist')}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <OrganizationSubmissionsView organization={organization} />
    </div>
  )
} 

OrganizationSubmissionsPage.loader = async ({ request, params }) => {
  const url = new URL(request.url)
  const { organizationId } = params

  const copyrighted = url.searchParams.get('copyrighted')
  const hasFingerprint = url.searchParams.get('hasFingerprint')
  const matchesArtistWatchlist = url.searchParams.get('matchesArtistWatchlist')
  const mismatchReleaseAndCopyrightYears = url.searchParams.get('mismatchReleaseAndCopyrightYears')

  const { data } = await client.query({ 
    query: QUERY,
    variables: {
      organizationId,
      copyrighted,
      hasFingerprint,
      matchesArtistWatchlist,
      mismatchReleaseAndCopyrightYears
    }
  })
  return data.screening
}

const renderWarningIcon = ({ node }) => {
  const fingerprint = JSON.parse(node.fingerprint)
  if (fingerprint.length > 0) {
    return (
      <Warning style={{ color: '#ffaa00'}}  />
    )
  } else {
    return (
      <Check style={{ color: "#008800" }} />
    )
  }
}

export function OrganizationSubmissionsView({
  organization
}) {
  const onNodesChecked = () => {}
  const onPerformAction = () => {}

  if (organization?.assetFingerprints) {
    const submissions = organization?.assetFingerprints
    return (
      <NodeTable 
        onNodesChecked={onNodesChecked}
        onPerformAction={onPerformAction}
        canCheck={true}  
        renderRowClassNames={({ node }) => {    
          return !node.uncopyrighted || !node.notMatchesArtistWatchlist || !node.originalReleaseDateMatchesCopyrightYears || !node.originalReleaseDateIsNotDifferentFromCopyrightAndPhonographicYear ? 'row-warning' : ''
        }}
        emptyHeading={i18n.t('No submissions found')}
        emptyText={i18n.t('No submissions found')}
        emptyIcon={MusicNote}
  
        isRowUnavailable={(row) => {
          return false        
        }}
        toolbarButtons={() => <></>}
        sx={{
          '& .row-success': {
            bgcolor: 'rgba(0, 255, 0, .1)'
          },
          '& .row-error': {
            bgcolor: 'rgba(255, 0, 0, .1)'
          },
          '& .row-warning': {
            bgcolor: 'rgba(255, 255, 0, .1)'
          }
        }}
        actions={[ 
        ]}
        columns={[
          {
            id: 'icon',
            name: 'Icon',
            type: 'icon',
            render: ({ node }) => {
              return (
                <FlaggedStatus isFlagged={node.uncopyrighted && node.originalReleaseDateMatchesCopyrightYears} />
              )
            }
          },
          {
            id: 'name',
            name: i18n.t('name'),
            type: 'name',
            render: ({ node }) => {
              return (
                <Link to={`/dashboard/screening/submission/${node.id}`}>{node.name}</Link>
              )
            }
          },
          {
            id: 'isrc',
            name: i18n.t('isrc'),
            type: 'text'
          },
          {
            id: 'originalReleaseDateIsNotDifferentFromCopyrightAndPhonographicYear',
            name: i18n.t('Release and C/P lines are in sync'),
            type: 'icon',
            render: ({ node }) => {
              return (
                <ReleaseAndCPDatesInSyncStatus isInSync={node.originalReleaseDateMatchesCopyrightYears} />
              )
            }
          },
          {
            id: 'originalReleaseDate',
            name: i18n.t('original-release-date'),
            type: 'text',
            render: ({ node }) => {
              const originalReleaseDate = node?.productFingerprint?.originalReleaseDate ?? node.originalReleaseDate
              return (
                <span title={moment(originalReleaseDate).format('YYY-MM-DD')}>{moment(originalReleaseDate).format('YYYY-MM-DD')}</span>
              )
            }
          },
          {
            id: 'cLine',
            name: i18n.t('c-copyright-line'),
            type: 'text',
            render: ({ node }) => { 
              return (
                <span>&copy; {node.pLineYear} {node.pLineText}</span>
              )
            }
          },
          {
            id: 'pLine',
            name: i18n.t('p-line'),
            type: 'text',
            render: ({ node }) => { 
              return (
                <span>&#8471; {node.pLineYear} {node.pLineText}</span>
              )
            }
          },
          {
            id: 'submitted',
            name: i18n.t('submitted'),
            type: 'text',
            render: ({ node }) => {
              const submitted = node?.productFingerprint?.submitted ?? node.created
              return (
                <span title={moment(submitted).format('YYY-MM-DD')}>{moment(submitted).fromNow()}</span>
              )
            }
          },
          {
            id: 'notMatchesArtistWatchlist',
            name: i18n.t('not-matches-artist-watchlist'),
            type: 'text',
            render: ({ node }) => {
              const notMatchesArtistWatchlist = node?.notMatchesArtistWatchlist ?? node?.productFingerprint?.notMatchesArtistWatchlist
              return (
                <span>
                  {notMatchesArtistWatchlist ?
                    <CheckCircle style={{ color: 'var(--success)'}} color="var(--success)" size={28} />
                  : (notMatchesArtistWatchlist === null) ?
                    <>-</>
                  :
                    <XCircle style={{ color: '#ff2200'}} color="#ff2200" size={28} />
                  }
                </span>
              )
            }
          },
          {
            id: 'fingerprint',
            name: i18n.t('fingerprint'),
            type: 'text',
            render: ({ node }) => { 
              return (
                <span>
                  {node.fingerprint}
                </span>
              )
            }
          } 
        ]}
        nodes={submissions}
      /> 
    )
  } else {
    return (
      <p>Error</p>
    )
  }
}

export default OrganizationSubmissionsPage