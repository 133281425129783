
import React from 'react';

import { Typography } from '@mui/material';

import { Flex, Image, Text, View } from '..';

export function GenericHeader({
  imageUrl,
  name,
  onClick,
  description,
  type,
  ...props
}) {
  const cursor = onClick instanceof Function ? 'pointer' : 'default'
  return (
    <View
      padding={1}
      {...props}
    >
      <Flex
        direction="row"
        gap={13}
      >
        {imageUrl && <Image src={imageUrl} />}
        <Flex
          direction="column"
          justifyContent="flex-end"
          gap={1}
          flex={1}
        >
          {type && <Text sx={{ cursor }} onClick={onClick}>{type}</Text>}
          {name && <Typography sx={{ cursor }} onClick={onClick} variant="h2">{name}</Typography>}
          {description && <Text sx={{ cursor }}>{description}</Text>}
        </Flex>
      </Flex>
    </View>
  )
}