import React from 'react';
import { Link } from 'react-router-dom'; 

import { NodeTable } from '../NodeTable';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function LabelTable({
  labels,
  emptyText,
  emptyHeading,
  emptyIcon,
  onNodesChecked
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <NodeTable
      emptyText={emptyText}
      emptyHeading={emptyHeading}
      Icon={emptyIcon}
      hasSubRows={true}
      nodes={labels}
      getSubRows={(node) => {
        return node.labels
      }}
      onNodesChecked={onNodesChecked}
      getContextMenuItems={({ nodes }) => [
        {
          name: t('Edit'),
          onClick: () => {
            navigate(`/dashboard/label/${nodes[0].slug}`)
          }
        }
      ]}
      columns={[
        {
          id: 'name',
          label: t('Name'),
          render: ({ node }) => {
            return (
              <Link to={`/dashboard/label/${node.slug}`}>{node.name}</Link>
            )
          }
        }
      ]}
    />
  )
}