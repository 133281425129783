import { gql, useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { NavTabBarItem, TabBar } from '@Components/TabBar';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

const QUERY = gql`
  query search($q: String!) {
    search(q: $q) {
      leads {
        entries {
          slug,
          lead {
            ...LeadFields
          }
        }
      }
      leadlists {
        ...LeadlistFields
      }
    }
  }
`

export function SearchResultLayout() {
  const { q } = useParams()
  return (
    <SearchResultLayoutView q={q}>
      <Outlet />
    </SearchResultLayoutView>
  )
}

export function SearchResultLayoutView({
  q,
  children
}) {
  const { t } = useTranslation()
  const { data, loading } = useQuery(QUERY, {
    variables: {
      q
    }
  })

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Box>
      <TabBar>
        <NavTabBarItem
          to={`/dashboard/search/${q}`}
          component={NavLink}
        >
          {i18n.t('Overview')}
        </NavTabBarItem>
        {data?.search?.leads?.length > 0 && (
          <NavTabBarItem
            to={`/dashboard/search/${q}/leads`}
            component={NavLink}
          >
            {i18n.t('Leads')}
          </NavTabBarItem>
        )}
        {data?.search?.leadlists?.length > 0 && (
          <NavTabBarItem
            to={`/dashboard/search/${q}/leadlists`}
            component={NavLink}
          >
            {i18n.t('Leadlists')}
          </NavTabBarItem>
        )}
      </TabBar>
      <Box style={{flex: 1}}>
        {children}
      </Box>
    </Box>
  )
}