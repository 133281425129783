/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import localforage from 'localforage';

import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ModalDialog } from '@Components/ModalDialog';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles'
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  Icon,
  InputLabel,
  LinearProgress,
  Select,
  Snackbar,
  Switch,
  Tab,
  TextField,
  Typography
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Close, CloudUpload, Filter, Filter2Outlined, LibraryBooks, Mail, QuestionMarkRounded } from '@mui/icons-material';
import { Bell, CaretCircleDoubleLeft, CaretCircleDoubleRight, CaretCircleLeft, CaretCircleRight, Compass, Disc, Funnel, House, ListBullets, ListDashes, MagnifyingGlass, Moon, Eye, MusicNote, Plus, PlusCircle, Question, Star, Sun, Tray } from 'phosphor-react';
import { Link } from '@Components/Link';
import { LoadingOverlay } from '@Components/LoadingOverlay';
import i18n from '@I18n';
import { Player, PlayerContext } from '@Components/Player';
import { gql, useQuery } from '@apollo/client';
import { ThemeContext } from '../../App';
import client from '../../client';

import { useLocation } from 'react-router-dom';

import { CreateLeadlist } from '../../views/CreateLeadlist';
import { LoginPage } from '../login';
import { SessionContext } from '../../contexts/SessionContext';
import { Image } from '../../components/Image';
import { NodeView } from '../../components/NodeView';
import { Tutorial } from '../../components/Tutorial';
import { logout } from '../../actions/login';

import { useApolloNetworkStatus } from '../../client'
import { GlobalLoadingIndicator } from '../../components/GlobalLoadingIndicator';
import { useSearchQuery } from '../../hooks';
import { OpenSidebar } from '../../icons/OpenSidebar';
import { CloseSidebar } from '../../icons/CloseSidebar';

import { createTeleporter } from "react-teleporter";

export const FooterToolBar = createTeleporter();

const drawerWidth = 340;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha('rgba(127, 127, 127, 1)', 0.15),
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  flexGrow: 1,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));
const handleCloseSnackbars = (message) => {
  message.open = false
}

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const LoginContext = createContext({
  showLoginModal: () => { }
})

export const DashboardLayoutContext = createContext({
  processes: [],
  isNavigating: false,
  setNavigating: (value) => { },
  setProcesses: (proceses) => { },
  alerts: [],
  addFailures: (promise) => { },
  failures: [],
  addProcess: (process) => { },
  removeProcess: (process) => { },
  setAlerts: (alerts) => { },
  addAlert: (alert) => { },
  failProcess: (Promise) => { },
  addFailure: (promise) => { },
  snackbarMessages: [],
  snackbar: null,
  setSnackbar: (value) => { },
  showSnackbarMessage: (messasge) => { },
  contextMenu: null,
  setContextMenu: (value) => { },
  apolloStatus: null,
  setApolloStatus: (value) => { },
  unreadNotices: []
})

const QUERY = gql`
  query {
    leads {
      countries {
        identifier
        slug
        name
      }
      leadlist(slug: "queue") {
        objects(new: true) {
          entries {
            lead {
              slug
            }
          }
        }
      }
    }
    notify {
      unreadNotifications {
        name,
        text,
        slug
      }
    }
  }
`

const QUERY_NOTICES = gql`
  query {
    notify {
      notices {
        delivered
        slug
        text
        name
        url
        created
        read
      }
    }
  }
`

const useListItemStyles = makeStyles({
  root: {
    opacity: 0.8,
    '&:hover': {
      opacity: 1
    }
  },
  selected: {
    opacity: 1,
    fontWeight: 700,
    'MuiTypography-root': {
      fontWeight: 700
    }
  }
})

export function DashboardLayout({
  numberOfNewLeads,
  unreadNotifications,
  countries
}) {
  const { t } = i18n
  const { session } = useContext(SessionContext)

  const [searchParams, setSearchParams] = useSearchParams()

  const [allowPushNotifications, setAllowPushNotifications] = useState(false)
  const [snackbarMessages, setSnackbarMessages] = useState([])
  const [position, setPosition] = useState(0)
  const [track, setTrack] = useState(null)
  const [duration, setDuration] = useState(0)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const audioEl = useRef(null)
  const [status, setStatus] = useState('')
  const [user, setUser] = useState(null)
  const navigate = useNavigate()
  const [snackbar, setSnackbar] = useState(null)
  const [editingNotesOfUri, setEditingNotesOfUri] = useState(null)
  const [notesDialogOpen, setNotesDialogOpen] = useState(false)
  const [createLeadlistDialogOpen, setCreateLeadlistDialogOpen] = useState(false)
  const [performActionOnNodesWithSubjectDialogOpen, setPerformActionOnNodesWithSubjectDialogOpen] = useState(false)
  const [performActionOnNodesWithSubjectType, setPerformActionOnNodesWithSubjectType] = useState(null)
  const [performActionOnNodeUris, setPerformActionOnNodeUris] = useState([])
  const [isShowingNotesDialog, setShowingNotesDialog] = useState(false)

  const queueMenuItemRef = useRef()

  const [contextMenu, setContextMenu] = useState(null)

  const handleCloseContextMenu = () => setContextMenu(null)

  const closeContextMenu = () => {
    setContextMenu(null)
  }

  const handleOnLogin = (user) => {
  }
  const handleOnLoginError = (user) => {

  }

  const listItemClasses = useListItemStyles()

  const [isShowingLoginDialog, setShowingLoginDialog] = useState(false)
  const handleSeek = (position) => {
    if (audioEl.current) {
      audioEl.currentTime = position
    }
  }
  const apolloStatus = useApolloNetworkStatus();

  const location = useLocation()

  const togglePlayPause = () => {
    if (audioEl.current) {
      if (status === 'playing') {
        audioEl.current.pause()
        setStatus('paused')
        clearInterval(playInterval)
      } else {
        audioEl.current.play()
        setStatus('playing')
        setDuration(audioEl.current.duration)
        setPlayInterval(
          setInterval(checkStatus, 100)
        )
      }
    }
  }
  const discoverMenuItemRef = useRef()

  const labelsMenuItemRef = useRef()

  const importMenuItemRef = useRef()

  const [fadeCenter, setFadeCenter] = useState(null)


  const [showSearch, setShowSearch] = useState(false)
  const handleTogglePlayPause = (event) => {
    togglePlayPause()
  }
  const loadTrack = (track) => {
    setTrack(track)
  }

  const showSnackbarMessage = (message) => {
    message.open = true
    const newSnackbarMessages = [...snackbarMessages, message]
    setSnackbarMessages(newSnackbarMessages)
  }

  const [playInterval, setPlayInterval] = useState(null)

  const checkStatus = () => {
    if (audioEl.current) {
      setPosition(audioEl.current.currentTime)
      setDuration(audioEl.current.duration)
    }
  }

  const searchQuery = useSearchQuery()
  const setSearchQuery = (newValues, options) => {
    setSearchParams({
      ...newValues,
      ...searchQuery
    }, options)
  }

  const { data: noticesData, loading, refetch: refetchNotices } = useQuery(QUERY_NOTICES)

  const newNotices = noticesData?.notify?.notices.filter(n => !n.delivered) ?? []

  const unreadNotices = noticesData?.notify?.notices.filter(n => !n.read) ?? []

  const tutorialSteps = [
    {
      title: i18n.t('Queue'),
      anchorEl: queueMenuItemRef,
      children: (
        <span>{i18n.t('This is the queue where you can find leads assigned to you')}</span>
      )
    },
    {
      title: i18n.t('Discover new leads'),
      anchorEl: discoverMenuItemRef,
      children: (
        <span>{i18n.t('Here you can find new leads to discover')}</span>
      )
    },
    {
      title: t('Manage labels'),
      anchorEl: labelsMenuItemRef,
      children: (
        <span>{i18n.t('Here you can manage labels and blacklist labels you dont want to collect leads from')}</span>
      )
    },
    {
      title: i18n.t('Import leads from external sources'),
      anchorEl: importMenuItemRef,
      children: (
        <span>{i18n.t('Import leads from CSV files')}</span>
      )
    }
  ]

  const pollNotices = () => {
    refetchNotices()
  }

  /*useEffect(() => {
    if (newNotices.length > 0) {
      let notice = newNotices[0]
      Notification.requestPermission().then(perm => {
        if(perm === 'granted') {
          new Notification(notice.name, {
            body: notice.text
          })
        }
      })
    }
  }, [noticesData])*/

  useEffect(() => {
    /*Notification.requestPermission().then(permission => {
      setAllowPushNotifications(true)
    })*/
    if (audioEl.current) {
      audioEl.current.addEventListener('timeupdate', (event) => {
        setPosition(event.target.currentTime)

      })
      audioEl.current.addEventListener('durationchange', (event) => {
        setDuration(event.target.duration)

      })
      audioEl.current.addEventListener('play', (event) => {
        if (event.target.paused) {
          setStatus('paused')
          clearInterval(playInterval)
        } else {
          setStatus('playing')
          setDuration(event.target.duration)
          setPlayInterval(
            setInterval(checkStatus, 100)
          )
        }
      })
    }
  }, [])

  const {
    mode,
    theme,
    applyTheme
  } = useContext(ThemeContext)

  const [processes, setProcesses] = useState([])
  const [failures, setFailures] = useState([])
  const [alerts, setAlerts] = useState([])
  const [open, setOpen] = React.useState(false);
  const isDark = theme?.palette?.mode === 'dark'
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const failProcess = (promise) => {
    removeProcess(promise)
    addFailure(promise)
  }

  const addFailure = (promise) => {
    setFailures([
      ...failures,
      promise
    ])
  }

  const q = searchParams.get('q')
  const countryId = searchParams.get('countryId')
  const isBlocked = searchParams.get('isBlocked') == 'true'

  const handleToggleDrawerOpen = () => {
    setOpen(!open)
    if (typeof window !== 'undefined') {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem('menuExpanded', JSON.stringify(!open))
      }
    }
  }

  const handleDrawerOpen = () => {
    localStorage.setItem('menuExpanded', JSON.stringify(true))
    setOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {

  }

  const [isOnboardingVisible, setOnboardingVisible] = useState(false);

  const [isFilterModalOpen, setFilterModalOpen] = useState(false)

  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = React.useState(null);
  const handleLogoutClick = (event) => {
    setProfileMenuAnchorEl(null)
    logout().then(() => {
      navigate("/logout")
    })
  }
  const isProfileMenuOpen = Boolean(profileMenuAnchorEl);
  const [isNavigating, setNavigating] = useState(false)
  useEffect(() => {
    const drawerOpen = localStorage.getItem('menuExpanded')
    // eslint-disable-next-line eqeqeq
    setOpen(drawerOpen == 'true')
    localforage.getItem('hasUndergoneTutorial').then((hasUndergoneTutorial) => {
      if (hasUndergoneTutorial != 'true') {
        setOnboardingVisible(true)
      }
    })
  }, [])
  const handleProfileMenuOpen = (event) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = (event) => {
    setProfileMenuAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const showFilterModal = event => {
    setSearchParams({
      showFilterModal: true
    })
    setFilterModalOpen(true)
  }

  const showLoginModal = () => {
    if (!searchParams.has('showLoginModal')) {
      setSearchParams({
        showLoginModal: true
      })
    }
  }


  const filterBy = searchParams.get('filterBy')

  const [showLoginDialog, setShowLoginDialog] = useState(false)

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';

  const handleCreateNew = (event) => {
  }

  useEffect(() => {
    if (searchParams.get('showLoginModal') === 'true') {
      setShowLoginDialog(true)
    } else {
      setShowLoginDialog(false)
    }
    if (searchParams.get('showFilterModal') === 'true') {
      setFilterModalOpen(true)
    } else {
      setFilterModalOpen(false)
    }
  }, [])

  const addProcess = (promise) => {
    return new Promise((resolve, reject) => {
      promise = promise.then((results) => {
        resolve(results)
      }).finally(() => {
        removeProcess(promise)
      }).catch(e => {
        showSnackbarMessage({
          severity: 'error',
          description: t(`Oops! Houston, we have a problem!`)
        })
        reject(e)
      })
      setProcesses([
        ...processes,
        promise
      ])
      return promise
    })
  }
  const removeProcess = (promise) => {
    let newProcesses = [...processes];
    newProcesses.splice(newProcesses.indexOf(promise), 1)
    setProcesses(newProcesses)
  }

  const [libraryQ, setLibraryQ] = useState('')

  const handleSearchFormSubmit = (e) => {
    e.preventDefault();
    doSearch(searchQuery);
    return false;
  }
  const doSearch = (q) => {
    if (/^aloaded:*/g.test(q)) {
      const parts = q.substr('aloaded:'.length).split(/:/g)
      if (parts[0] === 'search') {
        q = parts.slice(1)
        navigate(`/dashboard/search?q=${encodeURIComponent(q)}`)
        return
      }
      navigate(`/${parts.join('/')}`)
      return
    }
    navigate(`/dashboard/search?q=${encodeURIComponent(q)}`)
  }

  const handleCloseNotesDialog = (event) => {
  }

  const handleClosePerformActionOnNodesWithSubjectDialog = (event) => {
  }

  return (
    <PlayerContext.Provider value={{ track, togglePlayPause, setTrack, position, setPosition, loadTrack, status, duration, setDuration }}>
      <LoginContext.Provider value={{ showLoginModal }}>
        <DashboardLayoutContext.Provider value={{ unreadNotices, apolloStatus, contextMenu, setContextMenu, showSnackbarMessage, isNavigating, setNavigating, failures, failProcess, addFailure, processes, setProcesses, addProcess, removeProcess, alerts, setAlerts }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>

            <Drawer
              variant="permanent"
              open={open}
            >
              <DrawerHeader>
                <IconButton onClick={handleToggleDrawerOpen}>

                  {theme.direction === 'rtl' ? (
                    open ? (
                      <CloseSidebar />
                    ) : (
                      <OpenSidebar />
                    )
                  ) : (
                    open ? (
                      <OpenSidebar />
                    ) : (
                      <CloseSidebar />
                    )
                  )}
                </IconButton>
              </DrawerHeader>
              <Divider />
              {[
                {
                  id: 'overview',
                  items: [
                    {
                      id: 'home',
                      href: '/dashboard/overview',
                      text: t('Home'),
                      Icon: House
                    }
                  ]
                },
                {
                  id: 'queue',
                  items: [
                    {
                      anchorEl: queueMenuItemRef,
                      id: 'home',
                      href: '/dashboard/queue',
                      text: t('Queue'),
                      showAlert: numberOfNewLeads > 0,
                      badge: numberOfNewLeads > 0 && {
                        content: numberOfNewLeads.toLocaleString(),
                        color: 'error'
                      },
                      Icon: Tray
                    }
                  ]
                },
                {
                  id: 'discover',
                  name: t('Discover'),
                  items: [
                    {
                      anchorEl: discoverMenuItemRef,
                      id: 'discover',
                      href: '/dashboard/discover',
                      text: t('Discover'),
                      Icon: Compass // () => <i className="icon icon-record-label" style={{color: '#ff3333', textDecoration: 'line-through', fontSize: 28, margin: 0}} />
                    }
                  ]
                },
                {
                  id: 'labels',
                  name: t('Discover'),
                  items: [
                    {
                      anchorEl: labelsMenuItemRef,
                      id: 'labels',
                      href: '/dashboard/labels',
                      text: t('Labels'),
                      Icon: Disc // () => <i className="icon icon-record-label" style={{color: '#ff3333', textDecoration: 'line-through', fontSize: 28, margin: 0}} />
                    }
                  ]
                },
                {
                  id: 'star',
                  name: 'Import',
                  items: [
                    {
                      anchorEl: importMenuItemRef,
                      id: '',
                      href: '/dashboard/import',
                      text: t('Import data'),
                      Icon: CloudUpload // () => <i className="icon icon-record-label" style={{color: '#ff3333', textDecoration: 'line-through', fontSize: 28, margin: 0}} />
                    }
                  ]
                },
                {
                  id: 'playlists',
                  name: t('Playlists'),
                  items: [
                    {
                      anchorEl: labelsMenuItemRef,
                      id: 'playlists',
                      href: '/dashboard/playlists',
                      text: t('Playlists'),
                      Icon: MusicNote // () => <i className="icon icon-record-label" style={{color: '#ff3333', textDecoration: 'line-through', fontSize: 28, margin: 0}} />
                    }
                  ]
                },
                {
                  id: 'screening',
                  name: t('Screening'),
                  items: [
                    {
                      anchorEl: labelsMenuItemRef,
                      id: 'submissions',
                      href: '/dashboard/screening',
                      text: t('submissions'),
                      Icon: Eye // () => <i className="icon icon-record-label" style={{color: '#ff3333', textDecoration: 'line-through', fontSize: 28, margin: 0}} />
                    }
                  ]
                }
              ].map(({ items, id }, index) => (
                <List key={id}>
                  {items.map(({ Icon, onClick, anchorEl, badge, href, id, text }) => {
                    const selected = location.pathname.indexOf(href) === 0
                    return (
                      <ListItemButton
                        key={id}
                        classes={listItemClasses}
                        disablePadding
                        selected={selected}
                        component={onClick instanceof Function ? Button : Link}
                        ref={anchorEl}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                          fontWeight: selected ? 'bold' : 'normal'
                        }}
                        onClick={onClick}
                        href={href}
                      >
                        {badge ?
                          <Badge
                            badgeContent={badge.content}
                            color={badge.color}
                          >
                            <ListItemIcon
                              classes="list-item-icon"
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                              }}
                            >
                              <Icon weight={selected ? 'fill' : 'bold'} size={28} />
                            </ListItemIcon>
                          </Badge>
                          :
                          <ListItemIcon
                            classes="list-item-icon"
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            <Icon weight={selected ? 'fill' : 'bold'} size={28} />
                          </ListItemIcon>
                        }
                        <ListItemText
                          primary={text}
                          sx={{ opacity: open ? 1 : 0, fontWeight: selected ? 'bold' : 'normal' }}
                        />
                      </ListItemButton>
                    )
                  })}
                </List>

              ))}
              {/*<Library open={open} onSetOpen={() => setOpen(true)} />*/}
              <Box sx={{ display: 'flex', padding: 1, flexDirection: 'row', alignItems: 'center' }}>
                <Image src={track?.imageUrl} style={{ height: open && '100%', width: !open && '100%', aspectRatio: '1/1' }} />
                {open && <Box sx={{ flex: 1, padding: 1, display: 'flex', flexDirection: 'column' }}>
                  <NodeView value={track?.name} />
                  <NodeView value={track?.artists} />
                </Box>}
              </Box>
            </Drawer>
            <Box sx={{ overflow: 'hidden', height: '100vh', display: 'flex', flexDirection: 'column', flexGrow: 1, flex: 1, p: 0 }}>
              <Toolbar sx={{ borderBottom: '1px solid rgba(127, 127, 127, .3)' }}>
                {/*<IconButton
                  onClick={() => window.history.back()}
                >
                  <CaretCircleLeft size={28} />
                </IconButton>
                <IconButton
                  onClick={() => window.history.forward()}
                >
                  <CaretCircleRight size={28} />
                </IconButton>*/}
                <Box sx={{ flexGrow: 1 }} />
                <form style={{ visibility: 'hidden' }} onSubmit={handleSearchFormSubmit}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      onFocus={() => navigate(`/dashboard/search/${encodeURIComponent(q)}`)}
                      onChange={(event) => { setSearchQuery(event.target.value); /* navigate(`/dashboard/search/${encodeURIComponent(event.target.value)}`, { replace: true }) */ }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </Search>
                </form>
                <Box sx={{ flexGrow: 1 }} />
                <Fab
                  onClick={() => navigate(`/dashboard/lead/create`)}
                  sx={{ padding: '5pt 13pt', gap: 1 }}
                  variant="extended"
                  color="primary"
                >
                  <Plus size={18} />{i18n.t('Add leads')}
                </Fab>
                <Box sx={{ display: { xs: 'none', md: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 } }}>

                  {/*<IconButton onClick={() => showFilterModal()}>
                  <Funnel size={28} />
              </IconButton>*/}
                  {session ?
                    <>
                      <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={() => navigate(`/dashboard/notifications`)}
                        color="danger"
                      >
                        {(unreadNotifications?.length > 0 || unreadNotices?.length > 0) && false ?
                          <Badge badgeContent={unreadNotifications?.length ?? unreadNotices?.length} color="error">
                            <Bell />
                          </Badge>
                          : <Bell />}
                      </IconButton>
                      <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={() => setOnboardingVisible(true)}
                        color="inherit"

                      >
                        <Question />
                      </IconButton>
                      <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"

                      >
                        {session?.imageUrl ?
                          <Avatar src={session?.imageUrl} />
                          :
                          <AccountCircle />
                        }
                      </IconButton>
                      <Menu
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={profileMenuAnchorEl}
                        open={isProfileMenuOpen}
                        onClose={handleProfileMenuClose}
                      >
                        <MenuItem onClick={handleLogoutClick} disableRipple>
                          {i18n.t('logout')}
                        </MenuItem>
                      </Menu>
                    </>
                    :
                    <>
                    </>
                  }
                </Box>

                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>

                </Box>
              </Toolbar>
              <div className="container">
                {isFilterModalOpen &&
                  <Box sx={{ padding: 1, flexDirection: 'row', gap: 1, alignItems: 'center', display: 'flex' }}>
                    <FormControlLabel
                      control={
                        <Switch aria-label={i18n.t("Show blocked")} defaultValue={isBlocked} onChange={(event) => setSearchQuery({ isBlocked: event.target.checked }, { replace: true })} />
                      }
                      label={i18n.t('Show blocked')}
                    />
                    <FormControl sx={{ minWidth: 220 }}>
                      <InputLabel>{i18n.t('Country')}</InputLabel>
                      <Select
                        value={countryId}
                        onChange={(event) => {
                          setSearchParams(params => {
                            params.set('country', event.target.value)
                            return params
                          })
                        }}
                      >
                        <MenuItem value={''}>{i18n.t('All countries')}</MenuItem>
                        {countries?.map(c => (
                          <MenuItem
                            value={c.identifier}
                            key={c.identifier}
                          >
                            {i18n.t(c.name)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button onClick={() => setSearchQuery({ showFilterModal: false })} />
                  </Box>
                }
              </div>
              <GlobalLoadingIndicator />
              <Box sx={{ flexGrow: 1, overflow: 'auto', background: theme.palette.mode === 'dark' ? 'linear-gradient(-180deg, var(--fade-color), transparent)' : 'transparent' }}>
                <Outlet />
                <FooterToolBar.Target />
              </Box>
              {track?.previewUrl && <audio autoPlay style={{ display: 'none' }} src={track?.previewUrl} ref={audioEl} />}
              {track && (
                <Player
                  position={position}
                  status={status}
                  onSeek={handleSeek}
                  onTogglePlayPause={handleTogglePlayPause}
                  track={track}
                />
              )}
              <Dialog open={showLoginDialog} fullScren={fullScreen}>
                <Box sx={{ padding: 3 }}>
                  <LoginPage />
                </Box>
              </Dialog>
              {(isNavigating) &&
                <LoadingOverlay />
              }
            </Box>
          </Box>
          <Snackbar
            open={snackbar ?? false}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            severity={snackbar?.severity}
            action={
              <React.Fragment>
                <Button color="secondary" size="small" onClick={snackbar?.action?.onClick}>
                  {snackbar?.action?.text}
                </Button>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseSnackbar}
                >
                  <Close fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
            message={snackbar?.description}
          />
          {snackbarMessages.map(snackbarMessage => (
            <Snackbar
              open={snackbarMessage.open}
              autoHideDuration={6000}
              onClose={() => handleCloseSnackbars(snackbarMessage)}
              key={snackbarMessage ? snackbarMessage.key : undefined}
              message={snackbarMessage ? snackbarMessage.description : undefined}
              action={
                <React.Fragment>
                  {snackbarMessage && snackbarMessage.actions instanceof Array && snackbarMessage?.actions.map(
                    action => (
                      <Button
                        key={action.id}
                        color="secondary"
                        size="small"
                        onClick={(event) => {
                          event.preventDefault()
                          if (action.onCommand instanceof Function) {
                            action.onCommand(snackbarMessage, action).then(result => {
                              handleCloseSnackbars(snackbarMessage)
                            })
                          } else {
                            handleCloseSnackbars(snackbarMessage)
                          }
                          return false
                        }}
                      >
                        {action.name}
                      </Button>
                    )
                  )}
                  <Button
                    color="secondary"
                    size="small"
                    onClick={(event) => {
                      event.preventDefault()
                      handleCloseSnackbars(snackbarMessage)
                      return false
                    }}
                  >
                    {i18n.t(snackbarMessage.dismissText ?? `Dismiss`)}
                  </Button>
                </React.Fragment>
              }
            >
              <Alert severity={snackbarMessage.severity} sx={{ width: '100%' }}>
                {snackbarMessage.description}
              </Alert>
            </Snackbar>
          ))}
          <Dialog open={isShowingLoginDialog} onCancel={() => { }}>
            <LoginPage onLogin={handleOnLogin} onError={handleOnLoginError} />
          </Dialog>

          <Dialog
            open={createLeadlistDialogOpen}
            onCancel={() => { }}
          >
            <DialogTitle>{i18n.t('createLeadlist')}</DialogTitle>
            <DialogContent>
              <CreateLeadlist
                onCancel={() => { }}
              />
            </DialogContent>
          </Dialog>
          <Menu
            open={contextMenu !== null}
            onClose={handleCloseContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            {contextMenu?.menuItems?.map(
              menuItem => {
                return (
                  <MenuItem
                    key={menuItem.id}
                    Icon={menuItem.Icon}
                    icon={menuItem.icon}
                    onClick={(event) => {
                      menuItem.onClick(event)
                      handleCloseContextMenu(event)
                    }}
                  >
                    {i18n.t(menuItem.title || menuItem.name)}
                  </MenuItem>
                )
              })}
          </Menu>
          {isOnboardingVisible && <Tutorial
            open={isOnboardingVisible}
            onClose={() => {
              localforage.setItem('hasUndergoneTutorial', 'true').then(() => {
                setOnboardingVisible(false)
              })
            }}
            steps={tutorialSteps}
          />}
          {/*<Dialog onClose={() => setSearchQuery({ showFilterModal: false })} open={isFilterModalOpen}>
            <Box sx={{ padding: 1, flexDirection: 'column', display: 'flex' }}>
              <FormControlLabel
                control={
                  <Switch aria-label={i18n.t("Show blocked")} defaultValue={isBlocked} onChange={(event) => setSearchQuery({ isBlocked: event.target.checked }, { replace: true })} />
                }
                label={i18n.t('Show blocked')}
              />
              <FormControl sx={{ minWidth: 220 }}>
                <InputLabel>{i18n.t('Country')}</InputLabel>
                <Select
                  value={countryId}
                  onChange={(event) => {
                    setSearchParams(params => {
                      params.set('country', event.target.value)
                      return params  
                    })
                  }}
                >
                  <MenuItem value={''}>{i18n.t('All countries')}</MenuItem>
                  {countries?.map(c => (
                    <MenuItem
                      value={c.identifier}
                      key={c.identifier}
                    >
                      {i18n.t(c.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField label={i18n.t('Search')} variant="standard" value={q} onChange={(event) => setSearchParams({ countryId }, { replace: true})} />
              <Button onClick={() => setSearchQuery({ showFilterModal: false})} />
            </Box>
          </Dialog>*/}

        </DashboardLayoutContext.Provider>
      </LoginContext.Provider>
    </PlayerContext.Provider>
  );
}

DashboardLayout.loader = async ({ params }) => {
  const { data } = await client.query({
    query: QUERY
  })
  return {
    countries: data.leads.countries,
    unreadNotifications: data?.notify?.unreadNotifications,
    numberOfNewLeads: data?.leads?.me?.numberOfNewLeads
  }
}

export default DashboardLayout