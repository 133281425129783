import React, { useContext } from 'react'

import { LinearProgress } from "@mui/material";
import { AppContext } from "../../contexts/AppContext";

export function GlobalLoadingIndicator() {
  const { apolloStatus, processes, navigating } = useContext(AppContext)
  if (/*apolloStatus?.numPendingQueries > 0 ||*/ navigating || processes.length > 0) {
    return  <LinearProgress />;
  } else {
    return <></>;
  }
}
