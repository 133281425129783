import React from 'react';
import { Flex } from '../Flex';
import { CircularProgress, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

export function LoadingPage({
  children,
  ...props
}) {
  const { t } = useTranslation()
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      style={{ 
      }}
      orientation="column"
      {...props}
    >
      <CircularProgress />
      <Typography variant="h6">{i18n.t('Loading')}</Typography>
      {children }
    </Flex>
  )
}