import React, { useEffect, useState } from 'react';

import { useLoaderData, useParams } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';

import client from '../../../../client'

import dayjs from 'dayjs'
import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Button, CircularProgress, Container, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Switch, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';

import i18n from '../../../../i18n';
import { UserView } from '../../../../components/UserView';

import { LEAD_STATUS_CODES } from '../../../../constants';

import { NavigationFooter } from '../../../../components/NavigationFooter';
import { FooterToolBar } from '../../layout';

const ADD_MESSAGE_MUTATION = gql`
  mutation addMessageToLead(
    $logged: Date!,
    $slug: String!,
    $text: String!,
    $status: String!
  ) {
    addMessageToLead(
      slug: $slug,
      text: $text,
      status: $status,
      logged: $logged
    ) {
      message {
        slug
      }
    }
  }
`

const QUERY = gql`query getLead($slug: String!) {
  moodler {
    users {
      username
      id
    }
  }
  leads {
    labels {
      slug
      name
    }
    lead(slug: $slug) {
      ...LeadFields
      contacted,
      label {
        name
        slug
      }
      contractValueInEur
      messages {
        created
        slug
        status
        user {
          username
          imageUrl
        }
        text
      }
    }
  }
}`

const MUTATION = gql`mutation editLead(
  $slug: String!,
  $isBlocked: Boolean,
  $notes: String,
  $email: String,
  $whoUsername: String,
  $contacted: Date,
  $shouldContact: Date,
  $insight: String,
  $socials: String,
  $labelSlug: String
) {
  editLead(
    slug: $slug,
    notes: $notes,
    isBlocked: $isBlocked,
    email: $email,
    contacted: $contacted, 
    shouldContact: $shouldContact,
    whoUsername: $whoUsername,
    insight: $insight,
    labelSlug: $labelSlug,
    socials: $socials
  ) {
    lead {
      slug
    }
  }
}`

export function EditLeadOverviewPage() {
  const { slug } = useParams() 
  return <EditLeadOverview slug={slug} />
}

export function EditLeadOverview({
  onUpdated = () => {},
  slug
}) {
  
  const [newMessage, setNewMessage] = useState('')

  const [status, setStatus] = useState(0)

  const { data, loading, refetch } = useQuery(QUERY, {
    variables: {
      slug
    }
  })

  const users = data?.moodler?.users
  const lead = data?.leads?.lead
  const labels = data?.leads?.labels

  const [
    updateLead,
    {
      loading: updating,
      data: updateLeadData,
      error: updateError
    }
  ] = useMutation(
    MUTATION, {
      onCompleted: () => {
        enqueueSnackbar(i18n.t('Lead updated'), { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(i18n.t('Sorry, an error occurred when updating the lead'), { variant: 'error' })
      }
    }
  )
  const [labelSlug, setLabelSlug] = useState(lead?.label?.slug) 
  const [notes, setNotes] = useState(lead?.notes || '')
  const [whoUsername, setWhoUsername] = useState('')
  const [contacted, setContacted] = useState(null)
  const [shouldContact, setShouldContact] = useState(null)
  const [email, setEmail] = useState(lead?.email || '')
  const [isBlocked, setBlocked] = useState(lead?.isBlocked)
  const [insight, setInsight] = useState('')
  const [socials, setSocials] = useState('')
  useEffect(() => {
    setNotes(lead?.notes)
    setSocials(lead?.socials)
    if (users) {
      setWhoUsername(lead?.who?.username)
    }
    setLabelSlug(lead?.label?.slug)
    setStatus(lead?.status)
    setInsight(lead?.insight)
    setEmail(lead?.email)
    setBlocked(lead?.isBlocked)
    if(lead?.contacted) {
      setContacted(dayjs(lead?.contacted))
    }
    if (lead?.shouldContact) {
      setShouldContact(dayjs(lead?.shouldContact))
    }
    setTimeout(() => {
    //  setLoading(false)
    }, 1000)
  }, [lead])
 
  const handleSubmit = (event) => {
    event.preventDefault()
    updateLead({
      variables: {
        slug: lead.slug,
        isBlocked: isBlocked ?? false,
        notes: notes ?? '',
        whoUsername: whoUsername ?? '',
        email: email ?? '',
        contacted: contacted?.format('YYYY-MM-DD'),
        shouldContact: shouldContact?.format('YYYY-MM-DD'),
        insight,
        socials
      }
    }).then(() => {
      /*refetch().then(() => {
        onUpdated()
      })*/
    })
  }
  return (
    <form onSubmit={handleSubmit}>      
      <Box sx={{ padding: 1}}>
        {
          loading ? 
            <Box sx={{ aspectRatio: '16/9', alignItems: 'center', justifyContent: 'center', display: 'flex', gap: 1, flexDirection: 'column' }}>
              <CircularProgress />
              <Typography variant="h6">{i18n.t('Loading')}</Typography>
            </Box>
          :
            <>
            <fieldset>
              <legend>{i18n.t('Contact details')}</legend>
                
                <Paper sx={{padding: 1, opacity: isBlocked ? 0.5 : 1, flexDirection: 'column'}}>
                  {/*<TextField
                    multiline
                    fullWidth
                    variant="standard"
                    label="Name"
                    readOnly
                    value={lead?.name} 
                  />*/}
                  <TextField
                    type="email"
                    multiline
                    fullWidth
                    label={i18n.t("E-mail")}
                    variant="standard"
                    onChange={(event) => setEmail(event.target.value)}
                    value={email} 
                  />
                  <TextField
                    multiline
                    fullWidth
                    variant="standard"
                    label={i18n.t("Socials")}
                    defaultValue={socials}
                    onChange={(event) => setSocials(event.target.value)}
                  />
                </Paper>
              </fieldset>
              <fieldset>
                <legend>{i18n.t('Label')}</legend>
                <Paper>
                  <FormControl fullWidth>
                    <InputLabel>{i18n.t('Label')}</InputLabel>
                    <Select
                      variant="outlined"
                      value={labelSlug}
                      defaultValue={labelSlug}
                      label={i18n.t("Label")}
                      sx={{
                        width: '100%'
                      }}
                      onChange={(event) => {
                        setLabelSlug(event.target.value)
                      }}
                    >
                      {labels.map(subLabel => (
                        <MenuItem
                          key={subLabel.slug}
                          value={subLabel.slug}
                        >
                          {i18n.t(subLabel.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              </fieldset>
              <fieldset>
                <legend>{i18n.t('Notes')}</legend>
                <Paper sx={{padding: 1, opacity: isBlocked ? 0.5 : 1, flexDirection: 'column'}}>
              
                  <TextField
                    multiline
                    fullWidth
                    variant="standard"
                    label={i18n.t("Notes")}
                    defaultValue={notes}
                    onChange={(event) => setNotes(event.target.value)}
                  />
                </Paper>
              </fieldset> 
              <fieldset>
                <legend>{i18n.t('Assignment')}</legend>
                <Paper sx={{padding: 1, opacity: isBlocked ? 0.5 : 1, flexDirection: 'column'}}>
                  <FormControl fullWidth>
                    <InputLabel>{i18n.t('Who')}</InputLabel>
                    <Select 
                      id="select-who"
                      variant="standard"
                      value={whoUsername}
                      sx={{
                        width: '100%'
                      }}
                      onChange={(event) => {
                        setWhoUsername(event.target.value)
                      }}
                    >
                      <MenuItem value={null}>{i18n.t('Unassigned')}</MenuItem>
                      {users?.map(user => (
                        <MenuItem key={user.username} value={user.username}>{user.username}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Paper>
              </fieldset>
              <fieldset>
                <legend>{i18n.t('Contact')}</legend>
                <Paper sx={{padding: 1, opacity: isBlocked ? 0.5 : 1, flexDirection: 'column'}}>
                  <DatePicker
                    labelId={i18n.t("contacted")} 
                    label={i18n.t("Contacted at")}
                    sx={{
                      width: '100%'
                    }}
                    variant="standard"
                    value={dayjs(contacted)}
                    onChange={(value) => {
                      setContacted(value)
                    }}
                  />
                </Paper>
              </fieldset>
              <fieldset>
                <legend>{i18n.t('Contract value')}</legend>
                <Paper sx={{opacity: isBlocked ? 0.5 : 1, flexDirection: 'column'}}>        
                  <TextField
                    label={i18n.t('Contract Value')}
                    value={lead.contractValueInEur?.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'EUR'}) ?? '-'}
                    sx={{ textAlign: 'right' }}
                    readOnly
                  />
                </Paper>
              </fieldset>
            </>
           }
      </Box>
      <Box sx={{ marginTop: 1, alignItems: 'center', display: 'flex', flexDirection: 'row', gap: 2}}>
        <LoadingButton loading={updating} type="submit" variant="contained">{i18n.t('Save')}</LoadingButton>
      </Box>
    </form>
  )
}

EditLeadOverview.loader = async ({ params: { slug }}) => {
  const { data } = await client.query({
    query: QUERY,
    variables: {
      slug
    }
  })
  return {
    labels: data.leads.labels,
    lead: data.leads.lead,
    users: data.moodler.users 
  }
}