
import React from 'react';

import { Box, Avatar, Typography } from '@mui/material';

export function UserView({
  user,
  size,
  subtitle
}) {
  return (
    <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center'}}>
      <Avatar size={size} src={user.imageUrl} />
      <Box sx={{flexDirection: 'column'}}>
        <Typography variant="body1">{user.username}</Typography>
        {subtitle}
      </Box>
    </Box>
  )
}