import {gql} from '@apollo/client';

import localForage from 'localforage';

import client from '../client';
import { makeid } from '../utils';

export async function getCurrentUser() {
  try {
    const { data } = await client.query({
      query: gql`query {
        moodler {
          me {
            imageUrl
            username
          }
        }
      }`,
    })
    let user = data?.me
    return user
  } catch (e) {
    throw e
  }
}

async function refreshToken() {
  let strTokenAuth = await localForage.getItem('session')
  let tokenAuth = JSON.parse(strTokenAuth)
  let token = tokenAuth.token
  const data = await this.fetchAPI(
    `mutation refreshToken($token: String!) {
      refreshToken(token: $token) {
        token,
        payload,
        refreshExpiresIn
      }
    }
    `,
    {
      variables: {
        token
      }
    }
  )
  return data?.refreshToken
}

export async function startLogin() {
  const {
    protocol,
    hostname,
    port,
    pathname,
    search
  } = window.location
  const redirectTo = `${protocol}//${hostname}:${port}${pathname}?${search}`
  await localForage.setItem('redirectTo', redirectTo)
  const url = '/login' // `${process.env.REACT_APP_LOGIN_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_REDIRECT_URI)}`
  setTimeout(
    () => {
      window.location.href = url
    }, 
    500
  )
}

export async function generateTokenFromAuthorizationCode(code) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/auth/api/token`,
      {
        method: 'POST',
        body: JSON.stringify({
          code    
        }), 
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const data = await response.json()
    return data?.tokenAuth
  } catch (e) {
    throw e
  }
}

export async function generateTokenFromUsernameAndPassword(username, password) {
  try {
    const { data } = await client.mutate({
      mutation: gql`mutation TokenAuth($username: String!, $password: String!) {
        tokenAuth(username: $username, password: $password) {
          token
          payload
          refreshExpiresIn
        }
      }`, 
      variables: {
        username,
        password
      }
    }) 
    if (!data?.tokenAuth) {
      throw new Error("Could not log in");
    }
    return data?.tokenAuth
  } catch (e) {
    throw e
  }
}

export async function loginWithAuthorizationCode(code) {
  const result = await generateTokenFromAuthorizationCode(code)
  const { token } = result
  await localForage.setItem('token', token)
  const user = await getCurrentUser()
  let session = {
    id: makeid(23),
    user,
    token
  }
  await localForage.setItem('session', session)
  return session
}

export async function loginWithUsernameAndPassword(username, password) {
  const result = await generateTokenFromUsernameAndPassword(username, password)
  const { token } = result
  await localForage.setItem('token', token)
  const user = await getCurrentUser()
  let session = {
    id: makeid(23),
    user,
    token
  }
  await localForage.setItem('session', session)
  return session
}

export async function logout() {
  await localForage.removeItem("token")
  await localForage.removeItem("session")
}

export async function loginWithJwtToken(token) {
  await localForage.setItem('token', token)
  const user = await getCurrentUser()
  let session = {
    id: makeid(23),
    user,
    token
  }
  await localForage.setItem('session', session)
  return session     
}

export async function getSession() {
  const session = await localForage.getItem('session')

  return session
}


export async function uploadFiles(files, onUpdate) {
  // @ts-ignore
  const token = await localForage.getItem('token');
  var data = new FormData()
  data.append('file', files[0])
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/leads/api/uploads`,
    {
      body: data,
      method: 'POST', 
      headers: {
        'Authorization': `JWT ${token}`
      }
    }
  )
  if (!response.ok) {
    throw new Error("Failed to upload file")
  }
  return await response.json()
}