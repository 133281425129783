import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigation } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';

export default function Root({

}) {
  const navigation = useNavigation()
  const { navigating, setNavigating } = useContext(AppContext)
  useEffect(() => {
    console.log("navigation.state", navigation.state)
    setNavigating(navigation.state === 'loading' || navigation.state === 'submitting')
  }, [navigation.state])

  return (
    <Outlet />
  )
}