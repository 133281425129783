import React from 'react';

import { Link as ReactLink } from 'react-router-dom'
import { Link as MUILink } from '@mui/material'

export const Link = React.forwardRef(({
  href,
  children,
  Component='a',
  ...props
}, ref) => {
  return (
    <MUILink
      component={ReactLink}
      ref={ref} 
      to={href}
      {...props}
    >
      {children}
    </MUILink>
  )
})