import React from 'react';

export function FlaggedStatus({
    isFlagged
}) {
    if (isFlagged === null) {
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <div style={{ color: '#aaaaaa' }}>⏺</div><span>No data</span>
            </div>
        )
    }
    if (isFlagged) {
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <div style={{ color: '#008800' }}>⏺</div><span>Probably safe</span>
            </div>
        )
    } else {
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <div style={{ color: '#ff8800' }}>⏺</div><span>Flagged</span>
            </div>
        )
    }
}