import React, { createContext } from 'react'

export const SortContext = createContext({
  setSortBy: () => {},
  sortBy: null,
  nodes: [],
  checkedNodeIds: {},
  selectedNodeIds: {},
  setSelectedNodeIds: () => {},
  setCheckedNodeIds: () => {},
  sortByDirection: 1
})

export function getNodeValue({ node, column }) {
  let value = node[column.id]
  
  if (value instanceof Object && node?.value) {
    return value.name
  } else if (node?.lead instanceof Object) {
      return getNodeValue({ node: node.lead, column })
  } else if (node?.node instanceof Object) {
      return getNodeValue({ node: node.node, column })
  } else if (!isNaN(node)) {
    return value
  } else {
     return value
  }
}  