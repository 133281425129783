import React from 'react';

import { useSearchParams } from 'react-router-dom';
import { LeadlistView } from '../leadlist/overview';
import { LeadlistPageLayout } from '../leadlist/layout';

export function DiscoverView({
  slug='discover'
}) { 
  return (
    <>
      <LeadlistPageLayout
        slug={slug}
      >
        <LeadlistView
          showContactButton={false}
          showWho={true}
          slug={slug}
          hasRevisions={true}
        />
      </LeadlistPageLayout>
    </>
  )
}

export function DiscoverPage() {
  const [searchParams, setSearchParams] = useSearchParams()

  let slug = searchParams.get('revisionId', 'discover') ?? 'discover'

  return ( 
      <DiscoverView slug={slug} /> 
  )
}

export default DiscoverPage;
