import React, { useState } from 'react';

import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';

import { NavigationFooter } from '../../../../components/NavigationFooter';

import client from '../../../../client';

const QUERY = gql`
  query getUpload($slug: String!) {
    importify {
      upload(slug: $slug) {
        slug
        name
      }
    }
  }
`

export function UploadOverview({
  upload
}) {
  const navigate = useNavigate()

  const handleBackClicked = () => {
    navigate('/dashboard/import')
  }

  const handleSubmit = (event) => {
    navigate(`../fields`)
  }

  const handleNextClicked = (event) => {

  }

  if (upload) { 
    return (
      <>
        <form onSubmit={handleSubmit}>
          <NavigationFooter
            onBackClicked={handleBackClicked}
            onNextClicked={handleNextClicked}
          />
        </form>
      </>
    )
  }
}

export function UploadOverviewPage() {
  const upload = useLoaderData()
  return <UploadOverview upload={upload} />
}

UploadOverviewPage.loader = async ({ params: { slug } }) => {
  const { data } = await client.query({
    query: QUERY,
    variables: {
      slug
    }
  });
  return data.importify.upload
}