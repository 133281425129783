// @ts-nocheck

import Dropzone from 'react-dropzone';
import i18n from '../../i18n';
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const DropZone = ({
  onFilesUploaded,
  language = 'en',
  defaultUploadedFiles = [],
  onUploadFiles = () => {},
  accept
}) => {
  const [uploadedFiles, setUploadedFiles] = useState(defaultUploadedFiles) 
  const [uploadTasks, setUploadTasks] = useState([])
  const [isDraggingOver, setDraggingOver] = useState(false)
  const onDragEnter = (event) => {
    setDraggingOver(true)
  }
  const onDragLeave = (event) => {
    setDraggingOver(false)
  }
  const handleUploadUpdate = ({ uploadTasks, finishedUploads }) => {
    setUploadedFiles(Object.values(finishedUploads))
    setUploadTasks(Object.values(uploadTasks))
  }
  return (
    <Dropzone accept={accept} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={(acceptedFiles) => {
      onUploadFiles(acceptedFiles, handleUploadUpdate).then(
        (results) => {
          handleUploadUpdate(results)
          onFilesUploaded(Object.values(results.finishedUploads))
        }
      )
      setDraggingOver(false)
    }}>
      {({getRootProps, getInputProps, open}) => (
        <section className={'dropzone'}>
          <div {...getRootProps()}>
            <input {...getInputProps()} /> 
            {isDraggingOver ?
              <h3>{i18n.t('drop-files-here')}</h3>
            :
            <>
              <LoadingButton
                variant="contained"
                onClick={open}
              >
                {i18n.t('Select files')}
              </LoadingButton><br />
              <span>{i18n.t('Accepted file types', Object.values(accept ?? []).flat().map(s => s))}</span>
            </>
          }
          {uploadedFiles?.length > 0 &&
          <>
            <table>
              <tbody>
                {uploadedFiles.map((file) => {
                  return (
                    <tr key={file.url}>
                      <td></td>
                      <td>
                        <span>{file.name}</span>
                      </td>
                      <td>
                        <Button
                          color="default"
                          onClick={() => {
                            const newUploadedFiles = uploadedFiles
                            newUploadedFiles.splice(newUploadedFiles.indexOf(file), 1)
                            setUploadedFiles(newUploadedFiles)
                          }}
                        >
                          {i18n.t(language, 'remove')}
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
          }
          </div>
        </section>
      )}
    </Dropzone>
  )
}

export default DropZone