import { Button, CircularProgress, TextField } from "@mui/material";
import {  Content, ButtonGroup } from '@Components'; 

import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const CREATE_ARTISTLIST_MUTATION = gql`mutation createLeadlist(
  $name: String!
) {
  createLeadlist(name: $name) {
    leadlist {
      ...LeadlistFields
    }
  }

}`

export function CreateLeadlist({
  onClose = () => {},
  onCompleted = () => {}
}) {
  const { t } = useTranslation()
  const [
    createLeadlist,
    {
      data,
      loading
    }
  ] = useMutation(
    CREATE_ARTISTLIST_MUTATION,
    {
      onCompleted: () => {
        onCompleted()
        onClose()
      }
    }
  );

  const [name, setName] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    createLeadlist({
      variables: {
        name
      }
    })
    return false
  }
  return (
      <Content>
        <form onSubmit={handleSubmit}>
          <TextField isDisabled={loading} label={i18n.t('Name of list')} defaultValue={name} onChange={(event) => setName(event.target.value)} />
          <ButtonGroup>
            <Button isDisabled={loading} variant="contained" color="primary" type="submit">{loading ? <CircularProgress size={5} /> :t('Create list')}</Button>
          </ButtonGroup>
        </form>
      </Content>
  )
}