import { Box } from '@mui/material';
import React from 'react';

export function View({
  children,
  padding,
  ...props
}) {
  return (
    <Box
      sx={{
        padding
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
