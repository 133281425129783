import React, { useState } from 'react';

import { Alert } from '@mui/material';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';

import client from '../../../../client'

import { NavigationFooter } from '../../../../components/NavigationFooter';
import i18n from '../../../../i18n';
import { LoadingPage } from '../../../../components/LoadingPage';

const QUERY = gql`
  query getUpload($slug: String!) {
    importify {
      upload(slug: $slug) {
        slug
        status
        name,
        columnMappings {
          targetColumns {
            slug
            name
            columns {
              slug
              name
              selected
            }
          }
        }
      }
    }
  }
`

export function FinishUpload({
  upload
}) {
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const navigate = useNavigate()

  const handleBackClicked = () => {
    navigate('../fields')
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    navigate(`/dashboard/import/uploads?uploadId=${upload.slug}`)
    return false
  }
  const handleFinishClicked = (event) => {
  }

  if (upload) { 
    return (
      <>
        <form onSubmit={handleSubmit}>
          <Alert severity="success">
            {i18n.t('Import will be processed soon')}
          </Alert>
          <NavigationFooter
            saving={false}
            onFinishClicked={handleFinishClicked}
          />
        </form>
      </>
    )
  }
}

export function FinishUploadPage() {
  const upload = useLoaderData()
  return <FinishUpload upload={upload} />
}

FinishUploadPage.loader = async ({ params: { slug } }) => {
  const { data } = await client.query({
    query: QUERY,
    variables: {
      slug
    }
  });
  return data.importify.upload
}

export default FinishUploadPage
