import React, { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingPage } from '../components/LoadingPage';
import { loginWithAuthorizationCode } from '../actions/login';
import localForage from 'localforage';

export function CallbackPage() {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const code = query.get('code')
    loginWithAuthorizationCode(code).then(() => {
      localForage.getItem('redirectTo').then((targetUrl) => {
        window.location.href = targetUrl?.length > 0 ? targetUrl : "/dashboard"
      })
    })
  }, [])
  return (
    <LoadingPage />
  )

}