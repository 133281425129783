import React, { useContext, useState } from 'react';

import cx from 'classnames';
import { IconButton, MenuItem } from '@mui/material';
import { MoreHorizRounded } from '@mui/icons-material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

import { getBaseUrl } from '../../../utils';
import { SortContext } from '../../../contexts/SortContext';
import { Menu } from '../../Menu';

import i18n from '../../../i18n';
import { AppLayoutContext } from '../../../layouts/AppLayout';
import { CheckboxTableCell } from '..';
import { CaretDown, CaretLeft, CaretUp, Minus } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

export function NodeTableRow({
  checked,
  onDeleteClicked,
  node,
  draggable,
  level = 0,
  canDelete,
  hasSubRows,
  renderRowClassNames,
  columns,
  selected, // Object.keys(selectedNodeIds).length > 0
  rows
}) {
  const { t } = useTranslation()
  const [isExpanded, setExpanded] = useState(false)
  const {
    sortedNodes,
    setSelectedNodeIds,
    selectedNodeIds,
    getRealNode,
    getSubRows,
    getValueOfNode,
    checkedNodeIds,
    getContextMenuItems
  } = useContext(SortContext)

  const {
    contextMenu,
    setContextMenu
  } = useContext(AppLayoutContext)

  return (
    <>
      <tr
        tabIndex={0}
        style={{
          '--level': level
        }}
        className={cx('table-row', selected && `table-row-selected`, checked && `table-row-checked`, isExpanded && 'table-row-expanded', (level > 0) && 'table-row-nested', renderRowClassNames({ node }))}
        key={node?.slug}
        renderRowClassNames={renderRowClassNames}
        onKeyDown={(event) => {
          if (canDelete && event.key === 'Delete' && onDeleteClicked && selected) {
            event.preventDefault()
            onDeleteClicked()
            return false
          }
        }}
      >
        {hasSubRows &&
          <td>
            {rows?.length > 0 &&
              <IconButton onClick={() => setExpanded(!isExpanded)}>
                {isExpanded ? <CaretUp /> : <CaretDown />}
              </IconButton>
            }
            {level > 0 &&
              <div style={{ fontSize: 28, textAlign: 'center' }}>|</div>

            }
          </td>
        }
        <CheckboxTableCell slug={node.slug} checked={checked} />
        {columns.map(column => (
          <td style={{ ...column.style, textAlign: column.type === 'number' || column.type === 'currency' ? 'right' : 'left' }} key={column.field}>
            {column.render ? column.render({ node, column }) : getValueOfNode({ node, column })}
          </td>
        ))}
        <td>
          <PopupState variant="popover" popupId="context">
            {(popupState) => (
              <React.Fragment>
                <div className="icon-button">
                  <IconButton {...bindTrigger(popupState)}>
                    <MoreHorizRounded />
                  </IconButton>
                </div>
                <Menu {...bindMenu(popupState)}>
                  {getContextMenuItems({ nodes: [node] }).map(item => (
                    <MenuItem
                      onClick={() => { item.onClick(); popupState.close(); }}
                      key={item.id}
                    >
                      {i18n.t(item.name)}
                    </MenuItem>
                  ))}
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </td>
      </tr>
      {hasSubRows && isExpanded && rows.map(row => {
        const selected = node.slug in selectedNodeIds
        const checked = node.slug in checkedNodeIds
        return (
          <NodeTableRow
            hasSubRows={hasSubRows}
            columns={columns}
            level={level + 1}
            selected={selected}
            checked={checked}
            renderRowClassNames={renderRowClassNames}
            rows={getSubRows(row, 1)}
            node={row}
            onDeleteClicked={onDeleteClicked}
          />
        );
      })}
    </>
  )
}