import { CircularProgress } from '@mui/material';
import React from 'react';

export const LoadingOverlay = () => {
  return (
    <div style={{position: 'unset'}}>
      <div style={{pointerEvents: 'none', zIndex: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', position: 'fixed', left: 0, width: '100%', top: 0, height: '100%', background: 'rgba(255, 255, 255, .5)'}}>
        <CircularProgress size={128} />
      </div>
    </div>
  )
}