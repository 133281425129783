import React from 'react';

import { Box, Typography } from '@mui/material';

import { Outlet } from 'react-router-dom'

import { OrganizationsPage } from '.';

export default function OrganizationsLayout() {
  return (
    <Box sx={{ flex: 1, alignSelf: 'stretch', height: '100%', justifySelf: 'stretch', display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
      <Box sx={{ display: 'flex', alignItems: 'stretch', flex: '0 0 300pt', borderRight: '1px solid #aaa', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', p: 1, borderBottom: '1px solid #aaa' }}>
          <Typography>Organization</Typography>
        </Box>
        <Box sx={{ overflowY: 'scroll', flex: 1 }}>
          <OrganizationsPage />
        </Box>
      </Box>
      <Box sx={{ flex: 1, overflow: 'scroll' }}>
        <Outlet />
      </Box>
    </Box>
  )
}