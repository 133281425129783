import { PauseCircleFilled, PlayCircleFilled } from '@mui/icons-material';
import { IconButton, Slider, Box } from '@mui/material';
import React, { useContext } from 'react';
import { styled } from '@mui/system';
import { Image } from '../Image';
import { NodeView } from '../NodeView';
import styles from './index.module.scss';
import { formatDuration } from '../../utils';
 
export const PlayerContext = React.createContext({
  track: null,
  setTrack: () => {},
  play: (track) => {},
  togglePlayPause: () => {},
  loadTrack: () => {},
  position: 0,
  setPosition: (value) => {},
  duration: 0,
  setDuration: (value) => {},
  status: 'init'
})


const PlayerSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-track": {
    backgroundColor: 'rgba(127, 127, 127, .5)' ////color of the slider outside  teh area between thumbs
  }
}));

export function Player({
  onTogglePlayPause,
  status
}) {
  const {
    track,
    duration,
    position
  } = useContext(PlayerContext)
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
      }}
      
      className={styles.player}
    >
      
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 1}} className="player-controls">
        <Box sx={{ flex: 1, flexDirection: 'row', display: 'flex', padding: 1, justifyContent: 'center' }}>
          <IconButton 
            size={'large'}
            onClick={() => onTogglePlayPause()}
          >
            {status === 'playing' ?
              <PauseCircleFilled style={{fontSize: 48}} /> 
            :
              <PlayCircleFilled style={{fontSize: 48}} />
            }
          </IconButton>
        </Box> 
      </Box>
      <Box 
        sx={{
          flex: 1,
          gap: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',  
        }}
      >
       
        <Box sx={{ flex: 1, padding: 1, gap: .5, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
          <Box>-{track && (!isNaN(position) && !isNaN(duration)) ? formatDuration(Math.abs(position - duration)) : ':--'}</Box>
          <PlayerSlider value={position} max={30} />      
          <Box>{track && (!isNaN(position) && !isNaN(duration)) ? formatDuration(duration) : '-:--'}</Box>
        </Box> 
        
      </Box> 
    </Box>
  )
}
