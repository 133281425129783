import React from 'react';

import { Spotify } from 'react-spotify-embed';

export function FingerprintView({
    fingerprint
}) {
    return (
        <div> 
            
            {fingerprint?.external_metadata?.spotify && (
                <Spotify link={`https://open.spotify.com/track/${fingerprint.external_metadata.spotify.track.id}`} />
            )}
            {fingerprint.album && (
            <dl>
                <dt>Album</dt>
                <dd>{fingerprint.album.name}</dd>
            </dl>
            )}
            {fingerprint.label && (
            <dl>
                <dt>Label</dt>
                <dd>{fingerprint.label.name}</dd>
            </dl>
            )}
        </div>
    )
}