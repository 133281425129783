import React from 'react';

import styles from './index.module.scss';

export { TabBarItem, NavTabBarItem } from './TabBarItem'

export function TabBar({
  children
}) {
  return (
    <div className={styles.tabBar}>
      {children}
    </div>
  )
}