import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { CircularProgress, IconButton } from '@mui/material';

import i18n from '../../i18n';
import { Check } from 'phosphor-react';
import { Button } from '../Button';
import { LoadingButton } from '@mui/lab';
import { Send } from '@mui/icons-material';

const PERFORM_ACTION_ON_LEADS_MUTATION = gql`
mutation performActionOnLeads($slugs: [String!]!, $action: String!) {
  performActionOnLeads(slugs: $slugs, action: $action) {
    leads {
      slug
      contacted
    }
  } 
}`

export function ContactLeadButton({
  lead,
  onCompleted = () => {}
}) {
  const [performActionOnLeads, { loading }] = useMutation(PERFORM_ACTION_ON_LEADS_MUTATION, {
    onCompleted: () => {
      onCompleted()
    }
  })
  if (lead.contacted) {

    if (loading) {
      return (
        <CircularProgress />
      )
    }
    return (
      <IconButton variant="outlined"  onClick={() => performActionOnLeads({ variables: { action: 'uncontact', slugs: [lead.slug]}})}>
        <Check />
      </IconButton>
    )
  } else {
    return (
      <LoadingButton loading={loading} variant="contained" startIcon={<Send />} onClick={() => performActionOnLeads({ variables: { action: 'contact', slugs: [lead.slug]}})}>
        {i18n.t('Mark as contacted')}
      </LoadingButton> 
    )
  }
}