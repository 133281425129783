import { Menu as MuiMenu } from '@mui/material';
import { useContext } from 'react';
import { MenuContext } from '../MenuTrigger';

export function Menu({
  children,
  ...props
}) {
  const {
    open 
  } = useContext(MenuContext);
  return (
    <MuiMenu
      open={open}
      {...props}
    >
      {children}
    </MuiMenu>
  );
}