import React from 'react'

import { ImagesGrid } from '../ImagesGrid';
import { Box } from '@mui/material';

export const Image = ({
  name = '',
  src,
  srcs=null,
  icon='',
  isRounded=false,
  style = {},
  ...props
}) => {
  if (srcs) {
    return (
      <ImagesGrid className={`image ${isRounded && 'round'}`} srcs={srcs} style={{...style}} {...props} />
    )
  }
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        aspectRatio: '1/1',
        width: '100%',
        color: 'white',
        backgroundImage: `url('${src}')`,
        backgroundSize: 'cover',
        ...style
      }}
      {...props}
    >
      {!src && <>
      {icon?.length > 0 ?
      <i className={`icon fa fa-${icon}`} style={{fontSize: '30pt'}}></i>
      :
      <b className="icon" style={{fontSize: '10pt', textAlign: 'center'}}>{ name ? name[0] ?? '' : '' }</b>
      }
      </>
      }
    </Box>
  )
}