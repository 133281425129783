import { Info, InfoOutlined } from '@mui/icons-material';
import React from 'react';

export function EmptyView({
  heading,
  text,
  children,
  Icon=InfoOutlined,
  ...props
}) {
  return (
    <div className="empty-view flex flex-column items-center justify-center gap-13 aspect-ratio-16-6" {...props}>
      <div className="circle circle-primary">
        <Icon fill={true} size={128} />
      </div>
      <h1>{heading}</h1>
      <p>{text}</p>
      {children}
    </div>
  )
}