import React, { useEffect, useState } from 'react'

import { useParams, useSearchParams } from 'react-router-dom';

import { Box, Button, Dialog, DialogContent, CircularProgress, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { gql, useMutation, useQuery } from '@apollo/client';
import { LeadlistEntryTable } from '../../../components/LeadlistEntryTable';
import { LoadingButton } from '@mui/lab';

import { Flex } from '@Components';
import { LoadingPage } from '../../../components/LoadingPage';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { Envelope } from 'phosphor-react';
import { createTeleporter } from 'react-teleporter'
import moment from 'moment';

export const ToolBarTeleporter = createTeleporter()

const MUTATION = gql`mutation performActionOnLeadlistEntries(
  $action: String!,
  $slugs: [String]!
) {
  performActionOnLeadlistEntries(action: $action, slugs: $slugs) {
    leadlistEntries {
      slug
    }
  }
}`

const QUERY = gql`query getLeadlist(
  $slug: String!,
  $q: String,
  $isBlocked: Boolean,
  $p: Int,
  $country: String,
  $whoUsername: String,
  $hideAssignedArtists: Boolean
) {
  moodler {
    users {
      username
    }
  }
  leads {
    countries {
      slug
      identifier
      name
    }
    leadlist(slug: $slug) {
      ...LeadlistFields
      revisions {
        slug
        name
        created
      }
      objects(
        q: $q,
        isBlocked: $isBlocked,
        country: $country,
        hideAssignedArtists: $hideAssignedArtists,
        whoUsername: $whoUsername,
        p: $p
      ) {
        meta {
          numPages,
          p,
          total
        }
        entries {
          slug
          lead {
            ...LeadFields
          } 
        }
      }
    }
  }
}`

export function LeadlistView({
  slug,
  showContactButton = false,
  showWho = false,
  emptyIcon = Envelope,
  emptyText = i18n.t('No leads matching the criteria found'),
  emptyHeading = i18n.t('Sorry'),
  hasRevisions = false,
  displayColumns = []
}) {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [confirmActionDialogOpen, setConfirmActionDialogOpen] = useState(false)
  const [entries, setEntries] = useState([])
  const handleSubmitConfirmActionDialogForm = (event) => {
    event.preventDefault()
    performActionOnLeads({
      variables: {
        action,
        slugs: checkedLeadIds,
      }
    }).then(() => {
      setConfirmActionDialogOpen(false)
    })
    return false
  }

  const q = searchParams.get('q') ?? ''
  const country = searchParams.get('country') ?? ''
  const revisionId = searchParams.get('revisionId') ?? ''
  const p = parseInt(searchParams.get('p') ?? 0)
  const [action, setAction] = useState('')
  const whoUsername = searchParams.get('who')
  
  const isBlocked = JSON.parse(searchParams.get('isBlocked'))
  const hideAssignedArtists = JSON.parse(searchParams.get('hideAssignedArtists'))
  const [checkedLeadIds, setCheckedLeadIds] = useState([])
  const [confirmDeleteLeadsDialogOpen, setConfirmDeleteLeadsDialogOpen] = useState(false)
  const [deleteLeadIds, setDeleteLeadIds] = useState([])
  const handleSubmitDeleteLeadsDialogForm = (event) => {
    event.preventDefault()
    performActionOnLeads({
      variables: {
        action: 'delete',
        slugs: deleteLeadIds,
      }
    }).then(() => {
      setConfirmDeleteLeadsDialogOpen(false)
    })
    return false
  }
  const { data, refetch, fetchMore, loading } = useQuery(
    QUERY,
    {
      variables: {
        slug,
        q,
        p,
        isBlocked,
        whoUsername,
        country,
        hideAssignedArtists
      },
      onCompleted: (data) => {
        if (entries?.length > 0) {
          setEntries(data?.leads?.leadlist?.objects?.entries ?? [])
        } else {
          setEntries([...entries, ...data?.leads?.leadlist?.objects?.entries ])
        }
      }
    }
  )
  const [
    performActionOnLeads,
    {
      loading: performing
    }
  ] = useMutation(
    MUTATION, {
    onCompleted: () => {
      refetch().then(() => { }).catch(e => { })
    }
  }
  )

  const countries = data?.leads?.countries ?? []

  const handleNodesChecked = ({ nodes }) => {
    setCheckedLeadIds(nodes?.map(n => n?.slug))
  }

  const handleSubmitAction = (event) => {
    event.preventDefault()
    setConfirmActionDialogOpen(true)
    return false
  } 


  const leadlist = data?.leads?.leadlist
  const users = data?.moodler?.users ?? []
  console.log(leadlist?.objects)
  console.log("page", p, leadlist)
  return (
    <>
      <ToolBarTeleporter.Source>

      </ToolBarTeleporter.Source>
      <Flex direction="column" width="100%">
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <form onSubmit={handleSubmitAction}>
            <Box sx={{ padding: 1, display: 'flex', flexDirection: "row", width: "100%", alignItems: "center", gap: 1 }}>
              <FormControl sx={{ minWidth: 220 }}>
                <InputLabel>{i18n.t('Action')}</InputLabel>
                <Select
                  value={action}
                  onChange={(event) => {
                    setAction(event.target.value)
                  }}
                >
                  <MenuItem value={''}>{i18n.t('Select action')}</MenuItem>
                  <MenuItem value={'qualify'}>{i18n.t('Mark as qualified')}</MenuItem>
                  <MenuItem value={'unqualify'}>{i18n.t('Unmark as qualified')}</MenuItem>
                  <MenuItem value={'contact'}>{i18n.t('Mark as contacted')}</MenuItem>
                  <MenuItem value={'uncontact'}>{i18n.t('Unmark as contacted')}</MenuItem>
                  <MenuItem value={'block'}>{i18n.t('Hide')}</MenuItem>
                  <MenuItem value={'unblock'}>{i18n.t('Unhide')}</MenuItem>
                  <MenuItem value={'delete'}>{i18n.t('Delete')}</MenuItem>
                </Select>
              </FormControl>
              <LoadingButton type="submit" variant="contained" loading={performing} disabled={checkedLeadIds.length === 0 || action === null}>{i18n.t('Perform action')}</LoadingButton>
              <Box sx={{ flexGrow: 1 }} />
              <FormControl sx={{ minWidth: 220 }}>
                <InputLabel>{i18n.t('Assigned to')}</InputLabel>
                <Select 
                  id="who" 
                  value={whoUsername}
                  onChange={(event) => {
                    setSearchParams(params => {
                      params.set('who', event.target.value)
                      return params
                    })
                  }}
                >
                  <MenuItem value={null}>{i18n.t('All')}</MenuItem>
                  <MenuItem value={'@'}>{i18n.t('Only me')}</MenuItem>
                  {users?.map(user => (
                    <MenuItem key={user.username} value={user.username}>{user.username}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {hasRevisions && leadlist?.revisions instanceof Array && (
                <FormControl sx={{ minWidth: 220 }}>
                  <InputLabel>{i18n.t('Revision')}</InputLabel>
                  <Select
                    value={revisionId}
                    onChange={(event) => {
                      setSearchParams(params => {
                        params.set('revisionId', event.target.value)
                        return params
                      })
                    }}
                  >
                    <MenuItem selected={!revisionId || revisionId === 'discover'} value={'discover'}>{i18n.t('Cumulative')}</MenuItem>
                    {leadlist?.revisions.map(revision => (
                      <MenuItem key={revision.id} selected={revisionId === revision.slug} value={revision.slug}>{moment(revision.created).format('YYYY-MM-DD HH:MM')}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl sx={{ minWidth: 220 }}>
                <InputLabel>{i18n.t('Country')}</InputLabel>
                <Select
                  value={country}
                  onChange={(event) => {
                    setSearchParams(params => {
                      params.set('country', event.target.value)
                      return params
                    })
                  }}
                >
                  <MenuItem value={''}>{i18n.t('All countries')}</MenuItem>
                  {countries?.map(c => (
                    <MenuItem
                      value={c.identifier}
                      key={c.identifier}
                    >
                      {i18n.t(c.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Switch aria-label={i18n.t("Hide assigned artists")} defaultValue={hideAssignedArtists} onChange={(event) => setSearchParams({ country, isBlocked, hideAssignedArtists: event.target.checked, q: q ?? '' }, { replace: true })} />
                }
                label={i18n.t('Hide assigned artists')}
              />
              <FormControlLabel
                control={
                  <Switch aria-label={i18n.t("Show blocked")} defaultValue={isBlocked} onChange={(event) => setSearchParams({ country, hideAssignedArtists, isBlocked: event.target.checked, q: q ?? '' }, { replace: true })} />
                }
                label={i18n.t('Show blocked')}
              />
              <TextField label={i18n.t('Search')} variant="standard" value={q} onChange={(event) => setSearchParams({ country, hideAssignedArtists, q: event.target.value ?? '', isBlocked }, { replace: true })} />
            </Box>
          </form>
        </Box>
        {!loading && entries && (
          <LeadlistEntryTable
            showContactButton={showContactButton}
            showWho={showWho}
            onRefetch={() => refetch()}
            entries={entries}
            emptyIcon={emptyIcon}
            onNodesChecked={handleNodesChecked}
            emptyText={emptyText}
            emptyHeading={emptyHeading}
            displayColumns={displayColumns}
            onDeleteClicked={(deleteLeadIds) => {
              setAction('delete')
              setDeleteLeadIds(deleteLeadIds)
              setConfirmDeleteLeadsDialogOpen(true)
            }}
          />
        )}

        {loading ? (
          <Box
            sx={{ padding: 3, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}
          >
            <CircularProgress />
          </Box>
        ) : leadlist?.objects?.entries?.length > 0 && (
          <Box
            sx={{ padding: 3, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}
          >
            {(p < leadlist?.objects?.meta?.numPages) ?
              <Button
                variant="contained"
                onClick={() => {   
                  setSearchParams(params => {
                    params.set('p', p + 1)
                    return params
                  })
                }}
              >
                {i18n.t('fetch-more')}
              </Button>
              :
              <p>{i18n.t('no-more-results')}</p>
            }
          </Box>
        )}
      </Flex>
      <Dialog open={confirmActionDialogOpen} onClose={() => setConfirmActionDialogOpen(false)}>
        <DialogTitle>{i18n.t('Are you sure you want to %s %s leads?', t(action), checkedLeadIds.length)}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitConfirmActionDialogForm}>
            <LoadingButton
              loading={performing}
              variant="contained"
              color="error"
              type="submit"
            >
              {i18n.t('confirm %s', t(action))}
            </LoadingButton>
            <Button
              variant="secondary"
              onClick={() => setConfirmActionDialogOpen(false)}
            >
              {i18n.t('Cancel')}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={confirmDeleteLeadsDialogOpen} onClose={() => setConfirmDeleteLeadsDialogOpen(false)}>
        <DialogTitle>{i18n.t('Are you sure you want to delete %s leads?', t(action), deleteLeadIds.length)}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitDeleteLeadsDialogForm}>
            <LoadingButton
              loading={performing}
              variant="contained"
              color="error"
              type="submit"
            >
              {i18n.t('Delete leads', t(action))}
            </LoadingButton>
            <Button
              variant="secondary"
              onClick={() => setConfirmDeleteLeadsDialogOpen(false)}
            >
              {i18n.t('Keep leads')}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

export function LeadlistOverviewPage() {
  const { slug } = useParams()
  return (
    <LeadlistView slug={slug} />
  )
}

export default LeadlistOverviewPage