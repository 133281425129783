import React from 'react';

import { LoadingButton } from '@mui/lab';

import { Button } from '@mui/material';
import { ViewFooter } from '../ViewFooter';
import i18n from '../../i18n';

export function NavigationFooter({
  onBackClicked,
  saving=false,
  onNextClicked,
  nextLabel=i18n.t('Next'),
  finishLabel=i18n.t('Finish'),
  onFinishClicked
}) {
  return (
    <>
      <div style={{ height: 100 }}></div>
      <ViewFooter>
        <Button
          variant="outlined"
          sx={{ padding: '10pt 20pt' }}
          onClick={onBackClicked} 
        >{i18n.t('Back')}</Button>
        <div style={{ flex: 1}}></div>
        {onNextClicked instanceof Function && (
          <LoadingButton
            onClick={onNextClicked}
            loading={saving}
            variant="contained"
            type="submit" 
          >
            {nextLabel}
          </LoadingButton>
        )}
        {onFinishClicked instanceof Function && (
          <LoadingButton
            onClick={onFinishClicked}
            loading={saving}
            variant="contained"
            type="submit" 
          >
            {finishLabel}
          </LoadingButton>
         )}
      </ViewFooter>
    </>
  )
}