import React from 'react';

export function LIAPage() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>LIA</h1>
          <p>
            Aloaded AB
          </p>
          <p>
            Legitimate Interests Assessment (LIA)
          </p>
          <ul>
            <li>Identification of Legitimate Interest:
                <ul>
                  <li>Aloaded AB, a digital music distributor based in Stockholm, Sweden, has a legitimate interest in using publicly available information on artists and record labels' performance in the market.</li>
                  <li>The purpose of this processing is to develop our business strategies, track our own sales activities, enrich the publicly available artists' performance data with email contact information, and document customer interactions for the purpose of recruiting new customers for our label services and digital distribution.</li>
                  <li>Purpose Specification:
                    <ul>
                      <li>We use publicly available information on artists and record labels' performance in the market to:
                        <ol>
                          <li>Analyze market trends, consumer preferences, and industry dynamics to identify potential business opportunities, develop effective marketing strategies, and improve our services.</li>
                          <li>Track our own sales activities and evaluate the effectiveness of our digital distribution and label services.</li>
                          <li>Enrich the publicly available artists' performance data with email contact information to facilitate direct communication with relevant parties, such as artists, record labels, and industry professionals, for potential collaborations and business opportunities.</li>
                          <li>Document customer interactions and communications to maintain records of business discussions, negotiations, agreements, and feedback, which helps us in recruiting new customers and tailoring our services to meet their needs.</li>
                      </ol>
                    </li>
                    <li>
                      Necessity and Proportionality:
                      <ul>
                        <li>The processing of publicly available information on artists and record labels' performance is necessary to achieve the purposes mentioned above. It allows us to gain valuable insights into market trends, track our sales activities, establish direct communication channels, and maintain records for business development.</li>
                        <li>The processing is proportionate as we only collect and use publicly available information and enrich it with contact information obtained from reliable sources. We ensure that the data processed is limited to what is necessary and relevant to our business objectives, while respecting the privacy rights of individuals.</li>
                      </ul>
                    </li>
                    <li>
                      Balancing Test:
                      <ol>
                        <li>We have conducted a balancing test to weigh our legitimate interests against the rights and freedoms of the individuals whose data is processed. The following considerations support the balance:
                          <ol>
                            <li>The processing is based on publicly available information, which is already accessible to the general public and does not involve the collection of sensitive personal data.</li>
                            <li>The data processing is conducted in a manner that respects individuals' privacy rights and complies with applicable data protection laws and regulations.</li>
                            <li>We provide clear and transparent information to individuals about our data processing activities and their rights to control their personal information.</li>
                            <li>Individuals have the right to object to the processing of their personal data, and we provide an easy and accessible opt-out mechanism for them to exercise this right.</li>
                          </ol>
                        </li>
                        <li>
                          Ongoing Review:
                          <ul>
                            <li>We commit to conducting regular reviews of our data processing activities to ensure that our legitimate interests remain valid and relevant. We will reassess the balance between our legitimate interests and individuals' rights and freedoms if any significant changes occur in the processing activities or applicable legal requirements.
            This Legitimate Interests Assessment is documented and maintained to demonstrate our commitment to data protection principles and compliance with applicable regulations.
                            </li>
                          </ul>
                        </li>
                      </ol>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default LIAPage;