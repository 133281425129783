import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

import { LoadingButton } from '@mui/lab';

const TASK_QUERY = gql`
query getTask(
  $appName: String!,
  $taskFunc: String!,
  $arguments: [String]!,
  $kwArgs: [TaskExecutionArgumentInput!]
) {
  backgroundTask {
    task(
      appName: $appName,
      taskFunc: $taskFunc,
      arguments: $arguments,
      kwArgs: $kwArgs
    ) {
      taskName
    }
  }
}`

const EXECUTE_TASK_MUTATION = gql`
  mutation executeTask($appName: String!, $taskFunc: String!, $arguments: [String]!, $kwArgs: [TaskExecutionArgumentInput!]!) {
    executeTask(
      appName: $appName,
      taskFunc: $taskFunc,
      arguments: $arguments,
      kwArgs: $kwArgs
    ) {
      task {
        taskName
      }
    }
  }
`

export function TaskButton({
  appName,
  taskFunc,
  args=[],
  kwArgs={},
  children,
  ...props
}) {
  const [executeTask, { loading: processing, data: executeTaskData }] = useMutation(
    EXECUTE_TASK_MUTATION, {
      variables: {
        appName,
        taskFunc,
        arguments: args,
        kwArgs: Object.keys(kwArgs).map((attrName) => {
          return {
            attrName,
            attrValue: kwArgs[attrName]
          }
        })
      },
      onComplete: () => {
        refetch()
      }
    }
  )
  const { data, loading, refetch } = useQuery(
    TASK_QUERY,
    {
      variables: {
        appName,
        taskFunc,
        arguments: args,
        kwargs: Object.keys(kwArgs).map((attrName) => {
          return {
            attrName,
            attrValue: kwArgs[attrName]
          }
        })
      }
    }
  )
  const task = data?.backgroundTask?.task
  return (
    <LoadingButton variant="contained" color="info" {...props} loading={!!task} onClick={() => executeTask()}>
      {children}
    </LoadingButton>
  )
}
