import React from 'react';

export function ImagesGrid({
  srcs,
  style,
  ...props
}) {
  const numberOfImages = srcs.length >= 9 ? 9 : srcs.length >= 4 ? 4 : 1 
  return (
    <div style={{
        display: 'grid',
        gridTemplateColumns: srcs.length >= 9 ? '1fr 1fr 1fr' : srcs.length >= 4 ? '1fr 1fr' : '1fr',
        gridTemplateRows: srcs.length >= 9 ? '1fr 1fr 1fr' : srcs.length >= 4 ? '1fr 1fr' : '1fr',
        aspectRatio: '1/1',
        ...style
      }}
      {...props}
    >
      {srcs.slice(0, numberOfImages).map((src, i) => (
        <div key={`${src}${i}`} style={{width: '100%', aspectRatio: '1/1', backgroundSize: 'contain', backgroundImage: `url("${src}")`}} />
      ))}
    </div>
  )
}