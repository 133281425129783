import React from 'react'

import { Box } from '@mui/material'

export function Page({
  children,
  ...props
}) {
  return (
    <Box className="page" sx={{
      flex: 1, 
    }}>
      {children}
    </Box>
  )
}