import React from 'react'
import { Image } from '../Image'
import { Link } from '../Link'
import { Box, Paper } from '@mui/material'

export const Entry = ({
  href,
  imageUrl,
  name='',
  icon='',
  isRounded
}) => (
  <Link href={ href }>
    <Paper sx={{ display: 'flex', gap: 1, flexDirection: 'row' }}>
       <Box sx={{display: 'flex', padding: 1, flex: 1, flexDirection: 'row'}}>
        <h5>{name}</h5>
      </Box>
    </Paper>
  </Link>
)