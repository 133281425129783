
import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab'

import { Box, Button, Checkbox, FormControlLabel, Paper, Switch, TextField } from '@mui/material';

import { loginWithUsernameAndPassword } from '../actions/login';
import { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

export function LoginView({

}) {
  const { t } = useTranslation()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [isLoggingIn, setLoggingIn] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()

  const {
    addProcess
  } = useContext(AppContext);

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoggingIn(true)
    loginWithUsernameAndPassword(username, password).then(user => {
      setSuccess(true)
      setLoggingIn(false)
      navigate('/dashboard/overview').then(() => {
      })
 
    }).catch(e => {
      setError(true)
      setSuccess(false)
      setLoggingIn(false)
    })
    return false;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Paper sx={{padding: 1}}>
        <Box sx={{ display: 'flex', flexDirection: "column", gap: 1 }}>
          <TextField variant="standard" label="username" isRequired={true} defaultValue={username} onChange={(event) => setUsername(event.target.value)} />
          <TextField variant="standard" autocomplete="current-password" label="password" isRequired={true} type="password" defaultValue={username} onChange={(event) => setPassword(event.target.value)} />
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            {/*<FormControlLabel
              control={
                <Switch aria-label="Remember me" defaultValue={rememberMe} onChange={(event) => setRememberMe(event.target.checked)} />
              }
              label={i18n.t('Remember me')}
            />*/}
            <LoadingButton loading={isLoggingIn} type="submit" color="primary" variant="contained">{i18n.t('Log in')}</LoadingButton>
          </Box>
          <LoadingButton color="primary" variant="contained" onClick={() => window.open(`${process.env.REACT_APP_BACKEND_URL}/accounts/password_reset/`)}>{i18n.t('Forgot password?')}</LoadingButton>
        </Box>
      </Paper>
    </form>
  )
}