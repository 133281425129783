import React from 'react';

export function ReleaseAndCPDatesInSyncStatus({
    isInSync
}) {
    if (isInSync === null) {
      return (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
              <div style={{ color: '#88888' }}>⏺</div><span>Unknown</span>
          </div>
      )
    }
    if (isInSync) {
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <div style={{ color: '#008800' }}>⏺</div><span>Release and C/P dates are in sync</span>
            </div>
        )
    } else {
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <div style={{ color: '#ff8800' }}>⏺</div><span>Difference between C/P years and original release date</span>
            </div>
        )
    }
}