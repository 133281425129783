import { Alert, Button, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

export const ErrorView = ({
  error,
  resetErrorBounday
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const handleCloseSnackbar = () => {
      setOpen(false)
  }
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar} 
      message={'An error occurred'}
      action={ 
        <Button 
          color="secondary"
          size="small"
          onClick={(event) => {
            event.preventDefault()
            handleCloseSnackbar()
            return false 
          }}
        >
          {i18n.t(`Dismiss`)}
        </Button> 
      }
    >
      <Alert severity={'error'} sx={{width: '100%'}}>
        {i18n.t('An error occurred')}
      </Alert>
    </Snackbar>
  )
}