import React, { useState, useEffect, useMemo, createContext } from 'react';
import * as Sentry from "@sentry/react";
import {
  RouterProvider
} from "react-router-dom";

import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider } from '@emotion/react';
import { createTheme, CssBaseline, useMediaQuery } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import { ApolloProvider } from '@apollo/client';
import { clarity } from 'react-microsoft-clarity';
import './App.scss'

import router from './router';

import { AppContext } from './contexts/AppContext';
import { SessionContext } from './contexts/SessionContext';

import brand from './brand';

import "./index.css";
import client from './client';

import { themeOptions } from './theme';
import { getSession, startLogin } from './actions/login';

export const ThemeContext = createContext({
  mode: undefined,
  theme: null,
  applyTheme: (value) => { }
})

function App() {

  const { t } = useTranslation();
  const [processes, setProcesses] = useState([])
  const [messages, setMessages] = useState([])
  const [session, setSession] = useState(null)

  const [navigating, setNavigating] = useState(false)

  const [apolloStatus, setApolloStatus] = useState(null);

  const showMessage = (message) => {
    setMessages([...messages, message])
  }
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const { location } = window
    const search = new URLSearchParams(location.search)
    if (location.pathname.indexOf('/logout') === 0) {
      return
    }
    if (location.pathname.indexOf('/login') === 0) {
      return
    }
    if (search.has('code')) {
      const jwtToken = search.get('code')
    } else {
      getSession().then(value => {
        if (!value) {
          startLogin()
          return
        }
        setSession(value)
        setLoading(false)
      }).catch(e => {
        console.error(e)
      })
    }
  }, [])

  const [theme, setTheme] = useState(createTheme())

  const [mode, setMode] = useState('light')

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const applyTheme = (value) => {
    localStorage.setItem('theme', value)
    setMode(value)
  }

  useEffect(() => {
    if (process.env.REACT_APP_CLARITY_ID) {
      clarity.init(process.env.REACT_APP_CLARITY_ID)
    }
    let realMode = mode
    if (!realMode) {
      realMode = prefersDarkMode ? 'dark' : 'light'
    }
    setTheme(createTheme(themeOptions(realMode)))
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    document.body.style.backgroundColor = realMode === 'dark' ? 'rgba(10, 10, 10, 1)' : ''
    document.body.style.color = realMode === 'dark' ? 'rgba(255, 255, 255, .8)' : 'rgba(0, 0, 0, .9)'

    if (mode === 'yellow') {
      document.documentElement.style.setProperty('--primary', 'black')
      document.documentElement.style.setProperty('--primary-color', 'white')
    } else {
      document.documentElement.style.setProperty('--primary', brand.colors.primary)
      document.documentElement.style.setProperty('--primary-color', 'black')
    }

  }, [mode, prefersDarkMode])

  const addProcess = (promise) => {
    return new Promise((resolve, reject) => {
      promise = promise.then((results) => {
        resolve(results)
      }).finally(() => {
        removeProcess(promise)
      }).catch(e => {
        showMessage({
          severity: 'error',
          description: t(`Oops! Houston, we have a problem!`)
        })
        reject(e)
      })
      setProcesses([
        ...processes,
        promise
      ])
      return promise
    })
  }
  const removeProcess = (promise) => {
    let newProcesses = [...processes];
    newProcesses.splice(newProcesses.indexOf(promise), 1)
    setProcesses(newProcesses)
  }
  return (
    <ThemeContext.Provider value={{ theme }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <CssBaseline theme={mode} />
            <AppContext.Provider
              value={{
                navigating,
                setNavigating,
                apolloStatus,
                setApolloStatus,
                setMessages,
                messages,
                addProcess,
                processes,
                removeProcess,
                setProcesses
              }}
            >
              <SnackbarProvider />
              <SessionContext.Provider value={{ session, setSession }}>
                <RouterProvider router={router} />
              </SessionContext.Provider>
            </AppContext.Provider>
          </ThemeProvider>
        </ApolloProvider>
      </LocalizationProvider>
    </ThemeContext.Provider>
  );
}

export default App;