import React, { useState } from 'react';

export function Img({
  children,
  src: defaultSrc,
  srcSet,
  hiddenElement=<>-</>,
  fallbackSrc=null,
  ...props
}) {
  const [hidden, setHidden] = useState(false)
  const [visibility, setVisibility] = useState('visible')
  const [src, setSrc] = useState(defaultSrc)
  const handleError = (event) => {
    if (fallbackSrc) {
      setSrc(fallbackSrc)
    } else {
      setHidden(true)
    }
  }
  if (hidden) {
    return hiddenElement
  } 
  return (
    <img 
      src={src}
      srcSet={srcSet}
      onError={handleError}
      {...props}
    />
  )
}