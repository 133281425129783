import React from 'react';

import { Typography } from '@mui/material';

export function Text({
  children,
  ...props
}) {
  return (
    <Typography variant="body1" {...props}>
      {children}
    </Typography>
  )
}