import React from 'react';
import { Blurb } from '../Blurb';
import { ChangeView } from '../ChangeView';
import i18n from '../../i18n';
import { graphql } from 'graphql';
import { useFragment } from 'react-relay';
/*
export const LeadFragment = graphql`
  fragment LeadFragment on LeadsLeadEntity {
    onthlyGrowthListeners
    monthlyListeners
    imageUrl
    country {
      name
      slug
    }
    label {
      name
      slug
    }
    socials
    spotifyUrl
    blocked
    insight
    contacted 
    name
    slug
    notes
    email
    track {
      name
      slug
      previewUrl
    }
    who {
      username
      slug
    }
  }
`

export default function LeadFragmentView({ lead }) {
  const data = useFragment(
    LeadFragment,
    lead
  )
  return (
    <LeadBlurb {...data} />
  )
}
*/

export function LeadBlurb({
  monthlyListeners,
  weeklyGrowthPercent,
  ...props
}) {  
  return (
    <Blurb
      monthlyListeners={monthlyListeners}
      weeklyGrowthPercent={weeklyGrowthPercent}
      isRounded={true}
      {...props}
    >
      <small style={{opacity: 0.5}}>{i18n.t('x-monthly-listeners', {listeners: monthlyListeners?.toLocaleString(undefined, {maximumFractionDigits: 0, minimunFractionDigits: 0})})}</small>
      <ChangeView value={weeklyGrowthPercent} unit="%" />
    </Blurb>
  )
}