import Color from 'color'
import moment from "moment";
import { intervalToDuration } from 'date-fns'

// From https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export function makeid(length=23) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
  }
  return result;
}

export const parseMoodifyUrlToUri = (str) => {
  let url = new URL(str)
  let uri = `${process.env.REACT_APP_URI_SCHEME}:${url.pathname.substr(1).split(/\//g).join(':')}`
  return uri
}

export const getNodeUri = (node) => {
  if (!node) {
    throw new Error("Node is undefined")
  }
  if (!node.type) {
    throw new Error("Node type of " + JSON.stringify(node) + " is undefined")
  }
  if (node.uri?.indexOf('aloaded:') === 0) {
    return node.uri
  } else {
    return node?.uri ?? `aloaded:${node.type}:${node.id}`
  }
}

export const convertMoodifyUriToUrl = (str) => {
  const baseUrl = getBaseUrl()
  const parts = str.split(/:/g).slice(1)
  const url = `${baseUrl}/${parts.join('/')}`
  return url
} 

export const convertMoodifyUrlToUri  = (str) => {
  const baseUrl = getBaseUrl() 
  const url = `${baseUrl}/dashboard`
  const parts = str.substr(url.length + 1).split(/\//g)
  const uri = `aloaded:leads:${parts.join(':')}`
  return uri
}

export const getBaseUrl = () => {
  let hostname = 'localhost'
  let protocol = 'http:'
  if (window) {
    if (window.location.hostname) {
      hostname = window.location.hostname
    }
    if (window.location.port !== '80') {
      hostname += ':' + window.location.port
    }
  }
  return `${protocol}//${hostname}`
}

export const formatTime = (time) => {
  const time2 = moment(time)
  const now = moment()
  const days = now.diff(time2, 'days')
  if (days < 16) {
    return time2.fromNow()
  } else {
    return time2.format('YYYY-MM-DD')
  }
}

export const setUIColorAccent = (htmlColor) => {
  if (window && document) {
    const color = Color(htmlColor).hsl().object()
    const hue = color.h 
    const saturation = color.s * 0.4
    const luminance = color.l * 1.6
    document.documentElement.style.setProperty('--primary-h', hue + 'deg')
    document.documentElement.style.setProperty('--primary-s', saturation + '%')
    document.documentElement.style.setProperty('--primary-l', luminance + '%')
 
  }
}

export const formatMicroPopularity = (number) => {
  let output = ''
  let strNumber = number.toLocaleString(undefined, {minimumFractionDigits: 8, maximumFractionDigits: 8})
  let isOnDecimal = false
  let decimalNumber = 0
  for (let i = 0; i < strNumber.length; i++) {
    let char = strNumber[i]
    
    if (char === '.' || char === ',') {
      isOnDecimal = true
    } else {
      if (isOnDecimal) {
        if (decimalNumber % 3 === 0) {
          output += ' '
        }
      }
    }
    output += char
    if (isOnDecimal) {
      decimalNumber += 1
    }
  }
  return output
}

export function formatDuration(seconds) {
  // From https://stackoverflow.com/questions/48776140/format-a-duration-from-seconds-using-date-fns
  const duration = intervalToDuration({ start: 0, end: seconds * 1000})
  const zeroPad = (num) => String(num).padStart(2, '0')

  return [
    duration.hours,
    duration.minutes,
    duration.seconds,
  ]
  .filter(Boolean)
  .map(zeroPad)
  .join(':') 
}

export function getBaesUrl() {
  return `${window.location.protocol}//${window.location.hostname}${window.location.port !== 80 ? `:${window.location.port}` : ''}`
}