import React, { useState } from 'react';

import { Doorframe } from '@Components/Doorframe';
import { gql, useQuery } from '@apollo/client';
import { NavTabBarItem, TabBar, TabBarItem } from '../../../../components/TabBar'; 
import i18n from '../../../../i18n';
import client from '../../../../client';
import { Outlet, useLoaderData, useParams } from 'react-router-dom';
import { LoadingPage } from '../../../../components/LoadingPage';
import { ErrorView } from '../../../../components/ErrorView';

const QUERY = gql`
  query getUpload($slug: String!) {
    importify {
      upload(slug: $slug) {
        slug
        name
      }
    }
  }
`

export function UploadLayout() {
  const upload = useLoaderData()
  return <UploadLayoutView upload={upload} />
}

UploadLayout.loader = async ({ params: { slug } }) => {
  const { data } = await client.query({
    query: QUERY,
    variables: {
      slug
    }
  })
  return data.importify.upload
}

export function UploadLayoutView({
  upload
}) {  
  if (!upload) {
    return <></>
  }
  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-md-12">
            <h1>{upload.name}</h1>
            <TabBar>
              <NavTabBarItem to={`/dashboard/upload/${upload.slug}/overview`}>{i18n.t('1. Overview')}</NavTabBarItem>
              <NavTabBarItem to={`/dashboard/upload/${upload.slug}/fields`}>{i18n.t('2. Map fields')}</NavTabBarItem>
              <NavTabBarItem to={`/dashboard/upload/${upload.slug}/review`}>{i18n.t('3. Review')}</NavTabBarItem>
            </TabBar>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default UploadLayout
