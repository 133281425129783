import React from 'react'

import { LeadlistView } from '../leadlist/overview'
import i18n from '../../../i18n'
import { Tray } from 'phosphor-react'

export function QueueOverviewPage() {
  return <QueueOverview />
}

export function QueueOverview() {
  return (
    <> 
      <LeadlistView
        showContactButton={true}
        showWho={false}
        emptyHeading="Your queue is empty"
        emptyText="When you are assigned a lead it will be shown here"
        emptyIcon={Tray}
        slug="queue" 
      />
    </>
  )
}

export default QueueOverviewPage
