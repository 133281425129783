import React, { useState } from 'react';

import { Doorframe } from '@Components/Doorframe';
import { gql, useQuery } from '@apollo/client';
import { NavTabBarItem, TabBar, TabBarItem } from '../../../components/TabBar';
import DropZone from '../../../components/DropZone';
import i18n from '../../../i18n';
import { Outlet } from 'react-router-dom';

const QUERY = gql`
  query {
    importify {
      uploads {
        slug
        name
      }
    }
  }
`

export function ImportLayout({
}) { 
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>{i18n.t('Import')}</h1>
            <TabBar>
              <NavTabBarItem to="/dashboard/import/overview">{i18n.t('Upload')}</NavTabBarItem>
              <NavTabBarItem to="/dashboard/import/uploads">{i18n.t('History')}</NavTabBarItem>
            </TabBar>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default ImportLayout
