import React from 'react';

import { Box, Button, CardContent, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card } from '@mui/material';
import { Envelope, EnvelopeOpen } from 'phosphor-react';

import i18n from '../../i18n';
import { UserView } from '../UserView';
import { useTranslation } from 'react-i18next';

export function NotificationCard({
  subject,
  isRead,
  indirectNode,
  node,
  text,
  fieldId,
  value,
  slug,
  created,
  predicate,
  onToggleReadClick
}) {
  const { t } = useTranslation()
  return (
    <Card sx={{ backgroundColor: /*isRead*/ true ? 'unset' : 'rgba(127, 127, 255, .1)', fontWeight: isRead ? 'default' : 'bold'}}>
      <Box sx={{ display: 'flex', flexDirection: 'row'}}>
        <Box sx={{ flex: 1 }}>
          {subject && <UserView
            user={subject}  
            subtitle={
              <span style={{ opacity: 0.5 }}>
                <Link title={moment(created).format("YYYY-MM-DD H:m:s")} to={`/dashboard/notification/${slug}`}>{moment(created).fromNow()}</Link>
              </span>
            }
          />} 
        </Box>
        {/*<Button
          onClick={onToggleReadClick}
          variant="outlined"
          sx={{ padding: 2 }}
          color="inherit"
          startIcon={
            isRead ?
              <EnvelopeOpen />
            :
              <Envelope />
          }
        >
          {i18n.t(isRead ? 'Mark as unread' : 'Mark as read')}
        </Button>*/}
      </Box>
      <CardContent>
        <span style={{ opacity: 0.5 }}>
          {text ?? (
            <>
              {}
              {i18n.t(predicate ?? '')}
              {indirectNode && <Link to={`/dashboard/${indirectNode.type}/${indirectNode.slug}`}>{indirectNode.name}</Link>}
              {fieldId &&
                <> {i18n.t('set')} {i18n.t(fieldId)} to {i18n.t(value)} on </>
              }
              {node && <> {i18n.t(node.type ?? '')} <Link to={`/dashboard/${node.type}/${node.slug}`}>{node.name}</Link></>
              }
            </>
          )}
        </span>
        {indirectNode?.text?.length > 0 &&
          <Typography>{indirectNode.text}</Typography>
        }
      </CardContent>
    </Card> 
  )
}