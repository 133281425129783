import React, { useContext } from 'react';
import { GenericHeader } from '@Components/GenericHeader';
import { Page } from '@Components/Page';
import { gql, useQuery } from '@apollo/client';
import { Outlet, useParams } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import { enqueueSnackbar } from 'notistack'

import i18n from '../../../i18n'
import { EditLeadlist } from '../../../views/EditLeadlist';
import { AppLayoutContext } from '../../../layouts/AppLayout';
import { getBaseUrl } from '../../../utils';
import { useTranslation } from 'react-i18next';

const QUERY = gql`query getLeadlistLayout($slug: String!) {
  leads {
    leadlist(slug: $slug) {
      slug
      name
      ...LeadlistFields
    }
  }
}`

export default function LeadlistLayout() {
  const { slug } = useParams()
  return (
    <LeadlistPageLayout slug={slug}>
      <Outlet />
    </LeadlistPageLayout>
  )
}

export function LeadlistPageLayout({
  slug,
  children
}) {
  const { t } = useTranslation()
  const [editLeadlistDialogOpen, setEditLeadlistDialogOpen] = React.useState(false)

  const {
    contextMenu,
    setContextMenu
  } = useContext(AppLayoutContext)
 
  const { data, refetch } = useQuery(QUERY, {
    variables: {
      slug
    }
  });

  const handleOnGenericHeaderClick = (event) => {
    setEditLeadlistDialogOpen(true)
  }

  const handleOnGenericHeaderContextMenu = (event) => {
    event.preventDefault()
    event.preventDefault() 
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            menuItems: [
              {
                name: t('Rename'),
                onClick: () => {
                  setEditLeadlistDialogOpen(true)
                }
              },
              {
                name: t('Copy URL'),
                onClick: () => {
                  navigator.clipboard.writeText(`${getBaseUrl()}/dashboard/leadlist/${slug}`).then(() => {
                    enqueueSnackbar(t('URL copied to clipboard'), {
                      variant: 'success'
                    })
                  })
                }
              }
            ]
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
    return false
  }

  const leadlist = data?.leads?.leadlist
 
  return (
    <>
      <Page>
        {leadlist && (
          <GenericHeader
            //onClick={!(leadlist?.discover || leadlist?.queue) && handleOnGenericHeaderClick}
            imageSrcs={leadlist.nineEntries?.map(entry => entry?.artist?.imageUrl)}
            //onContextMenu={handleOnGenericHeaderContextMenu}
            name={leadlist?.name ?? 'Unnamed'}
            status={'success'} type={'leadlist'}
          />
        )}
        {children}
      </Page>
      <Dialog open={editLeadlistDialogOpen} onClose={() => setEditLeadlistDialogOpen(false)}>
        <DialogTitle>{i18n.t('Edit leadlist')}</DialogTitle>
        <DialogContent>
          <EditLeadlist slug={slug} onClose={() => {
            setEditLeadlistDialogOpen(false)
            refetch()
          }} />
        </DialogContent>
      </Dialog>
    </>
  ) 
}
