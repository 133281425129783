import { createContext } from 'react';

export const AppLayoutContext = createContext({
  processes: [],
  isNavigating: false,
  setNavigating: (value) => {},
  setProcesses: (proceses) => {},
  alerts: [],
  addFailures: (promise) => {},
  failures: [],
  addProcess: (process) => { },
  removeProcess: (process) => {},
  setAlerts: (alerts) => {},  
  addAlert: (alert) => {},
  failProcess: (Promise) => {},
  addFailure: (promise) => {},
  snackbarMessages: [], 
  snackbar: null,
  setSnackbar: (value) => {},
  showSnackbarMessage: (messasge) => {},
  contextMenu: null,
  setContextMenu: (value) => {},
  apolloStatus: null,
  setApolloStatus: (value) => {}
})