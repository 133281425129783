import React from 'react';

import { gql } from '@apollo/client'
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery, useMutation } from '@apollo/client';

import { Playlist } from '@Components/Playlist';
import { TaskButton } from '../../../../components/TaskButton';
import i18n from '../../../../i18n';

const MUTATION = gql`
  mutation syncPlaylistSortingToService(
    $start: Date!,
    $end: Date!,
    $playlistSlug: String!
    $sortByDirection: String!,
    $sortByFieldId: String!
  ) {
    syncPlaylistSortingToService(
      start: $start,
      end: $end,
      playlistSlug: $playlistSlug,
      sortByFieldId: $sortByFieldId,
      sortByDirection: $sortByDirection
    ) {
      playlist {
        entries {
          slug
        }
      }
    }
  }
`

const QUERY = gql`
  query getPlaylistTracks(
    $start: Date,
    $sortByFieldId: String,
    $sortByDirection: String,
    $end: Date,
    $slug: String!
  ) {
    moodler {
      playlist(
        slug: $slug
      ) {
        name,
        entries(
          start: $start,
          end: $end,
          sortByFieldId: $sortByFieldId,
          sortByDirection: $sortByDirection
        ) {
          slug,
          type
          number,
          node {
            slug,
            name
            enclosure {
              type
              contentType
              url
            }
            artists {
              name  
            }
            skips(start: $start, end: $end)
            streams(start: $start, end: $end)
            streamingQuality(start: $start, end: $end)       
          }       
        }
      }
    }
  }
`

export function PlaylistOverviewPage() {
  const { slug } = useParams()
  return (
    <PlaylistOverview slug={slug} />
  )
}

export function PlaylistOverview({
  slug
}) {
  const { t } = useTranslation()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const end = dayjs(searchParams.get('end') ?? dayjs())
  const start = dayjs(searchParams.get('start') ?? dayjs())
  const q = searchParams.get('q') ?? ''
  const sortByFieldId = searchParams.get('sortByFieldId')
  const sortByDirection = searchParams.get('sortByDirection') ?? 'asc'
  const [
    syncPlaylistSortingToService,
    {
      data: saveData,
      loading: savingChanges
    }
  ] = useMutation(MUTATION)

  const { data, refetch } = useQuery(QUERY, {
    variables: {
      slug,
      sortByFieldId,
      sortByDirection,
      start: start.format('YYYY-MM-DD') ?? null,
      end: end.format('YYYY-MM-DD') ?? null
    }
  })

  const performActionOnPlaylistEntries = () => {}

  const handlePerformAction = (action, nodeIds) => {
    return performActionOnPlaylistEntries(action.id, nodeIds)
  }

  const onSubmitForm = (event) => {
    event.preventDefault()
    return false
  }
  const handleNodesChecked = () => {

  }

  const handleUpdatePlaylistClick = () => {
    syncPlaylistSortingToService({
      variables: {
        playlistSlug: slug,
        start: start.format('YYYY-MM-DD') ?? null,
        end: end.format('YYYY-MM-DD') ?? null,
        sortByDirection,
        sortByFieldId
      }
    }).then(() => {

    })
  }
  return (
    <form method="POST" onSubmit={onSubmitForm}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
        <div style={{flex: 1}} />
        <DatePicker
          label={i18n.t("start")}
          value={start}
          onChange={(value) => {
            setSearchParams({
              start: value.format('YYYY-MM-DD'),
              end: end.format('YYYY-MM-DD'),
              q,
              sortByFieldId,
              sortByDirection
            })
          }}
        />
        <DatePicker
          label={i18n.t("end")}
          value={end}
          onChange={(value) => {
            setSearchParams({ 
              start: start.format('YYY-MM-DD'),
              end: value.format('YYYY-MM-DD'),
              q,
              sortByFieldId,
              sortByDirection
          })
          }}
        />
      </div>
      {data?.moodler?.playlist?.entries instanceof Array && (
        <Playlist
          slug={slug}
          start={start}
          end={end}
          entries={data?.moodler?.playlist?.entries}
          onNodesChecked={handleNodesChecked}
          onPerformAction={handlePerformAction}
          defaultSortByFieldId={sortByFieldId}
          defaultSortByDirection={sortByDirection}
          onSortByChanged={({ sortByFieldId, sortByDirection }) => {
            setSearchParams({
              start: start.format('YYYY-MM-DD'),
              end: end.format('YYYY-MM-DD'),
              q,
              sortByFieldId,
              sortByDirection
            })
          }}
        />
      )}
    </form>
  )
}

export default PlaylistOverviewPage