import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';


export default function SearchPage() {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="h1">{i18n.t('Search')}</Typography>
    </Box>
  )
}
