import React, { useEffect, useState } from 'react';

import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { enqueueSnackbar } from 'notistack';

import client from '../../../../client'

import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Button, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Snackbar, Switch, TextField, Typography } from '@mui/material';

import i18n from '@I18n';

import { NavigationFooter } from '../../../../components/NavigationFooter';
import { set } from 'date-fns';

const QUERY = gql`query getLabel($slug: String!) {
  leads {
    label(slug: $slug) {
      slug
      parent {
        slug
        name
      }
      name
      isAggregator
      blocked
      notes
      notes
    }
    labels {
      name
      slug
    }
  }
}`

const MUTATION = gql`
  mutation editLabel(
    $name: String!,
    $slug: String!,
    $parentSlug: String,
    $notes: String,
    $isBlocked: Boolean,
    $isAggregator: Boolean
  ) {
    editLabel(
      name: $name,
      slug: $slug,
      parentSlug: $parentSlug,
      isAggregator: $isAggregator,
      notes: $notes,
      isBlocked: $isBlocked
    ) {
      label {
        slug
        name
        isAggregator
        parent {
          slug
          name
        }
      }
    }
  }
`

EditLabelPage.loader = async ({ params: { slug }}) => {
  const { data } = await client.query({
    query: QUERY,
    variables: {
      slug
    }
  })
  return {
    label: data.leads.label,
    labels: data.leads.labels
  }
}

export function EditLabelPage() {
  const { label, labels } = useLoaderData()
  return (
    <LabelEditor label={label} labels={labels} />
  )
}

export function LabelEditor({
  onUpdated = () => {},
  label,
  labels
}) {

  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [
    updateLabel,
    {
      loading: updating,
      data: updatedLabelData,
      error: updateError
    }] = useMutation(
      MUTATION, {
        onCompleted: () => {
          enqueueSnackbar(i18n.t('Label updated'), { variant: 'success' })
        },
        onError: () => {
          enqueueSnackbar(i18n.t('Sorry, an error occurred when updating the label'), { variant: 'error' })
        }
      }
    )
  const [notes, setNotes] = useState(label?.notes || '')
  const [isBlocked, setBlocked] = useState(!!label?.blocked)
  const [isAggregator, setIsAggregator] = useState(label?.isAggregator)

  const [parent, setParent] = useState(null)

  useEffect(() => { 
    setNotes(label?.label?.notes) 
    setBlocked(!!label?.blocked)
    setName(label?.name)
    setIsAggregator(label?.isAggregator)
  }, [label])

  useEffect(() => {
    if (labels) {
      setParent(label?.parent?.slug)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }, [labels])

  const handleSubmit = (event) => {
    event.preventDefault()
    updateLabel({
      variables: {
        slug: label.slug,
        notes,
        parentSlug: parent,
        isAggregator,
        name,
        isBlocked
      }
    }).then(() => {
      if (updatedLabelData?.editLabel) {
        navigate(`/dashboard/label/${updatedLabelData.editLabel.label.slug}`, { replace: true })
      } else {
        //refetch().then(() => {})
      }
    })
  }
  return (
    <Box sx={{ padding: 1 }}> 
      <form onSubmit={handleSubmit}>
        <Typography variant="h4">{i18n.t('Edit label')}</Typography>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          {updateError && <Alert action={<Button size="small" onClick={() => {/*refetch()*/}}>{i18n.t('Try again')}</Button>} variant="error">{i18n.t('Couldnt load the label')}</Alert>}
          <Paper sx={{ opacity: isBlocked ? 0.5 : 1, padding: 1, flex: 1}}>
            {
              loading ? 
              <Box sx={{ aspectRatio: '16/9', alignItems: 'center', justifyContent: 'center', display: 'flex', gap: 1, flexDirection: 'column' }}>
                <CircularProgress />
                <Typography variant="h6">{i18n.t('Loading')}</Typography>
              </Box>
            :
              <Box sx={{flexDirection: 'column', display: 'flex', gap: 1}}>
                <TextField
                  multiline
                  fullWidth
                  label={i18n.t("Name")} 
                  onChange={(event) => setName(event.target.value)}
                  value={name} 
                />
                <TextField
                  multiline
                  fullWidth
                  label={i18n.t("Notes")}
                  defaultValue={notes}
                  onChange={(event) => setNotes(event.target.value)}
                />
                <FormControl fullWidth>
                  <InputLabel>{i18n.t('Parent label')}</InputLabel>
                  <Select
                    variant="outlined"
                    value={parent}
                    defaultValue={parent}
                    label={i18n.t("Parent label")}
                    sx={{
                      width: '100%'
                    }}
                    onChange={(event) => {
                      setParent(event.target.value)
                    }}
                  >
                    {labels.map(subLabel => (
                      <MenuItem
                        key={subLabel.slug}
                        value={subLabel.slug}
                      >
                        {i18n.t(subLabel.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControlLabel control={<Switch onChange={(event) => setIsAggregator(event.target.checked)} checked={isAggregator ?? false} />} label={i18n.t("Is aggregator")} />  
              </Box>
            }
          </Paper>
          <Box sx={{ padding: 1, display: 'flex', alignItems: {xs: 'center', lg: 'flex-start'}, flexDirection: {sm: 'row', lg: 'column'}}}>
            <FormControlLabel control={<Switch onChange={(event) => setBlocked(event.target.checked)} checked={isBlocked ?? false} />} label={i18n.t("Hide")} />   
          </Box>
          <NavigationFooter
            saving={updating}
            finishLabel={i18n.t('Save')}
            onFinishClicked={() => {}}
          />
        </Box>
      </form>
    </Box>
  )
}

export default EditLabelPage