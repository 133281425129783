import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, TextField } from '@mui/material';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { NodeTable } from '../../../../components/NodeTable';
import i18n from '../../../../i18n';
import { Button } from '../../../../components';
import { TaskButton } from '../../../../components/TaskButton';
import { useTranslation } from 'react-i18next';

const QUERY = gql`query getPlaylists($q: String!) {
  moodler {
    playlists(q: $q) {
      slug,
      name
    }
  }
}`

export function PlaylistsLibraryPage() { 
  const [searchParams, setSearchParams] = useSearchParams()

  const q = searchParams.get('q') ?? ''

  const { data, loading } = useQuery(QUERY, {
    variables: {
      q
    }
  });

  if (data?.moodler?.playlists) {
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1}}>
      <Box sx={{flex: 1}} />
      <TextField
        value={q}
        onChange={(e) => {
          setSearchParams({
            q: e.target.value ?? ''
          })
        }}
        placeholder="search"
      />
    </Box>
  }
  if (loading) {
    return <CircularProgress />
  }
  console.log(data?.moodler)
  if (data?.moodler?.playlists) {
    return ( 
      <PlaylistsLibraryView playlists={data?.moodler?.playlists} />
    )
  } else {
    return <></>
  }
}

export function PlaylistsLibraryView({
  playlists
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <h1 style={{ margin: 20 }}>{i18n.t('Spotify Playlists')}</h1>
      <NodeTable
        nodes={playlists}
        getContextMenuItems={({ nodes }) => [
          {
            label: 'View',
            onClick: ({ nodes }) => {
              navigate(`/dashboard/playlist/${nodes[0]?.slug}`)
            }
          }
        ]}
        columns={[
          {
            id: 'name',
            name: i18n.t('Playlist'),
            type: 'string',
            render: ({ node }) => {
              return (
                <Link to={`/dashboard/playlist/${node.slug}`}>{node.name}</Link>
              )
            }
          }
        ]}
      />
    </>
  )
}