import React from 'react';

export function CopyrightMatchStatus({
    uncopyrighted
}) {
    if (uncopyrighted) {
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <div style={{ color: '#008800' }}>⏺</div><span>Probably safe</span>
            </div>
        )
    } else {
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <div style={{ color: '#ff8800' }}>⏺</div><span>Potential infringement</span>
            </div>
        )
    }
}