import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import localForage from 'localforage';

import fragments from './fragments';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';

console.log(process.env.REACT_APP_BACKEND_URL)

export const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();


const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql/`,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await localForage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    }
  }
});

export const client = new ApolloClient({
  link: link.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(
      fragments
    )
  })
});

export default client

