import React from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as OpenSidebarSVG } from '../images/OpenSidebar.svg';

export function OpenSidebar({
  ...props
}) {
  return (
    <SvgIcon component={OpenSidebarSVG} {...props} inheritViewBox />
  )
}