import React from 'react';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import i18n from '../../../../i18n';
import { LoadingPage } from '../../../../components/LoadingPage';
import { ErrorView } from '../../../../components/ErrorView';

const QUERY = gql`
  query {
    importify {
      uploads {
        slug
        name
        status
      }
    }
  }
`

export function ImportUploadsPage() {
  return <ImportUploads />
}

export function ImportUploads({
}) { 
  const { data, loading } = useQuery(QUERY)
  
  if (loading) {
    return <LoadingPage />
  }
  if (!data) {
    return <ErrorView />
  }
  const uploads = data?.importify?.uploads
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>{i18n.t('Upload')}</th>
                  <th>{i18n.t('Status')}</th>
                </tr>
              </thead>
              <tbody>
                {uploads.map(upload => (
                  <tr>
                    <td><Link to={`/dashboard/upload/${upload.slug}`}>{upload.name}</Link></td>
                    <td>{i18n.t(upload.status)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default ImportUploadsPage