import React, { useContext, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import i18n from '@I18n'; 
import { NodeTable } from '../NodeTable';
import { TrackPlayButton } from '../TrackPlayButton';
import { Avatar, Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { UserView } from '../UserView';
import { LeadProgress } from '../LeadProgress';
import { Check, InfoOutlined, Send } from '@mui/icons-material';
import { Img } from '../Img';
import { gql, useMutation, useQuery } from '@apollo/client';
import { DashboardLayoutContext } from '../../pages/dashboard/layout';
import { AssignLeadSelect } from '../AssignLeadSelect';
import { ContactLeadButton } from '../ContactLeadButton';

export const EDIT_LEAD_MUTATION = gql`mutation editLead(
  $slug: String!,
  $whoUsername: String
) {
  editLead(slug: $slug, whoUsername: $whoUsername) {
    lead {
      slug
    }
  }
}`

export const ASSIGN_LEAD_MUTATION = gql`mutation assignLead(
  $slug: String!,
  $username: String
) {
  assignLead(slug: $slug, username: $username) {
    lead {
      slug
    }
  }
}`

const PERFORM_ACTION_ON_LEADS_MUTATION = gql`
mutation performActionOnLeads($slugs: [String!]!, $action: String!) {
  performActionOnLeads(slugs: $slugs, action: $action) {
    leads {
      slug
      contacted
    }
  } 
}`

const USERS_QUERY = gql`
  query {
    moodler {
      users {
        username
      }
    }
  }
`

export function LeadlistEntryTable({
  entries,
  onRefetch = () => {},
  emptyText = '',
  showContactButton=false,
  showWho=false,
  emptyHeading = '',
  emptyIcon = InfoOutlined,
  canDelete,
  onDeleteClicked = () => {},
  onNodesChecked = ({ nodes }) => [],
  displayColumns=[]
}) {
  const navigate = useNavigate()
  console.log(entries)
  const [selectedNodeIds, setSelectedNodeIds] = React.useState({})
  const {
    unreadNotices
  } = useContext(DashboardLayoutContext)
  const [
    editLead, 
    {
      data: editLeadData
    }
  ] = useMutation(
    EDIT_LEAD_MUTATION,
    {
      onCompleted: () => {
        onRefetch()
      }
    }
  )
  const { data: usersData } = useQuery(USERS_QUERY)

  const [performActionOnLeads] = useMutation(PERFORM_ACTION_ON_LEADS_MUTATION, {
    onCompleted: () => {
      onRefetch()
    }
  })

  const [assignLead] = useMutation(ASSIGN_LEAD_MUTATION, {
    onCompleted: () => {
      onRefetch()
    }
  })

  useEffect(() => {
    if (unreadNotices?.find(n => !n.delivered)) {
      onRefetch()
    }
  }, [unreadNotices])

  const users = usersData?.moodler?.users ?? []

  const allColumns = [
    {
      id: 'player',
      label: i18n.t('player'),
      render: ({ node }) => {
        console.log(node?.lead)
        return (
          node?.lead?.track && <TrackPlayButton node={node.lead} value={node?.lead?.track} />
        )
      }
    },
    {
      id: 'image',
      label: i18n.t('Image'),
      render: ({ node }) => {
        return <Avatar src={node?.lead?.imageUrl} alt={node?.lead?.name} height="30pt" style={{ aspectRatio: '1/1'}} />
      }
    },
    {
      id: 'name',
      headerStyle: {
        textAlign: 'left',
        width: '30pt'
      },
      name: i18n.t('Name'),
      render: ({ node }) => {
        return <Link to={`/dashboard/lead/${node.lead?.slug}`}>{node.lead?.name}</Link>
      }
    },
    {
      id: 'label',
      headerStyle: {
        textAlign: 'left',
        width: '30pt'
      },
      name: i18n.t('Label'),
      render: ({ node }) => {
        return <Link to={`/dashboard/label/${node.lead?.label?.slug}`}>{node.lead?.label?.name}</Link>
      }
    },
    {
      id: 'spotifyUrl',
      name: i18n.t('Spotify'),
      render: ({ node }) => {
        const { lead } = node
        return (
          <a href={lead.spotifyUrl} target="__blank">
            <img alt="" src="/images/spotify.svg" height="30pt" />
          </a>
        )
      }
    },
    {
      id: 'label',
      headerStyle: {
        textAlign: 'left',
        width: '30pt'
      },
      label: i18n.t('Label'),
      render: ({ node }) => {
        return (
          <Link to={`/dashboard/label/${node.lead?.artist?.label?.slug}`}>{node.name}</Link>
        )
      }
    },
    {
      id: 'country',
      name: i18n.t('Country'),
      render: ({ node }) => {
        const lead = node?.lead
        return (
          <Img
            alt={lead?.country?.name}
            fallbackSrc={null}
            src={`https://flagcdn.com/32x24/${lead?.country?.identifier?.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/32x24/${lead?.country?.identifier?.toLowerCase()}.png 2x,
                  https://flagcdn.com/48x36/${lead?.country?.identifier?.toLowerCase()}.png 3x`}

          />
        )
      }
    },
    {
      id: 'monthlyListeners',
      type: 'number',
      name: i18n.t('Monthly listeners'),
      headerStyle: {
        textAlign: 'right',
        width: '30pt'
      },
      render: ({ node }) => {
        const { lead } = node
        return (
          <span style={{ fontFamily: 'Courier'}}>{lead.monthlyListeners?.toLocaleString()}</span>
        )
      }
    },
    {
      id: 'monthlyGrowthPercent',
      type: 'number',
      name: i18n.t('Monthly growth percent'),
      headerStyle: {
        textAlign: 'right',
        width: '30pt'
      },
      render: ({ node }) => {
        const { lead } = node
        return (
          <span style={{ fontFamily: 'Courier'}}>{lead.monthlyGrowthPercent?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%</span>
        )
      }
    }/*,
          {
            id: 'monthlyGrowthListeners',
            type: 'number',
            name: i18n.t('Monthly listeners growth'),
            render: ({ node }) => {
              const { lead } = node
              return (
                <span style={{ fontFamily: 'Courier'}}>{lead.monthlyGrowthListeners?.toLocaleString()}</span>
              )
            }
          }*/,
    ...(showContactButton ? [{
      id: 'mark_as_contacted',
      name: i18n.t('Mark as contacted'),
      render: ({ node }) => {
        const lead = node?.lead
        return (
          <ContactLeadButton users={users} lead={lead} />
        )
      }
    }] : []),
    ...(showWho ? [{
      id: 'who',
      width: '130pt',
      name: i18n.t('Who'),
      render: ({ node }) => {
        const lead = node?.lead
        return (
          <AssignLeadSelect users={users} lead={lead} onCompleted={() => onRefetch()} />
        )
      }
    }] : []),
    {
      id: 'contractValueInEur',
      headerStyle: {
        textAlign: 'right',
        width: '30pt'
      },
      type: 'currency',
      textAlign: 'right',
      name: i18n.t('Contract value'),
      render: ({ node }) => {
        const lead = node.lead
        return (
          <span>{lead.contractValueInEur?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0, style: 'currency', currency: 'EUR' })}</span>
        )
      }
    },
    /*{
      id: 'email',
      type: 'number',
      name: i18n.t('E-mail'),
      render: ({ node }) => {
        const { lead } = node
        return (
          lead.email
        )
      }
    },
    {
      id: 'insight',
      type: 'text',
      name: i18n.t('Insight'),
      render: ({ node }) => {
        const { lead } = node
        return (
          lead.insight
        )
      }
    },
    {
      id: 'socials',
      type: 'text',
      name: i18n.t('Socials'),
      render: ({ node }) => {
        const { lead } = node
        return (
          lead.insight
        )
      }
    },
    {
      id: 'notes',
      type: 'text',
      render: ({ node }) => {
        const { lead } = node
        return lead.notes
      }
    },
    {
      id: 'progress',
      name: i18n.t('Progress'),
      render: ({ node }) => {
        return <LeadProgress statusCode={node?.lead?.status} />
      }
    },
    {
      id: 'who',
      name: i18n.t('Who'),
      render: ({ node }) => {
        const lead = node?.lead
        if (!lead?.who) {
          return <>-</>
        }
        return (
          <UserView user={lead?.who} />
        )
      }
    }*/
  ]
  let columns = allColumns
  if (displayColumns?.length > 0) {
    columns = allColumns.filter(c => displayColumns.find(dc => c.id === dc))
  }

  return (
    <>
      <NodeTable
        defaultSortByFieldId={"monthlListeners"}
        defaultSortByDirection={"desc"}
        tabIndex={0}
        emptyText={emptyText}
        emptyHeading={emptyHeading}
        emptyIcon={emptyIcon}
        canDelete={true}
        style={{ width: 'auto' }}
        onSelectedNodseChanged={(selectedNodeIds) => {
          setSelectedNodeIds(selectedNodeIds)
        }}
        onDeleteClicked={(nodes) => {
          onDeleteClicked(nodes)
        }}
        draggable={true}
        onNodesChecked={onNodesChecked}
        getRealNode={({ node }) => {
          return node.lead
        }}
        nodes={entries}
        getValueOfNode={
          ({node, column}) => {
            return node.lead[column.id]
          }
        }
        getContextMenuItems={({ nodes }) => [
          {
            name: i18n.t('Edit'),
            onClick: () => {
              navigate(`/dashboard/lead/${nodes[0]?.lead?.slug}`)
            }
          },
          {
            name: i18n.t('Delete'),
            onClick: () => {
              onDeleteClicked(nodes.map(n => n.slug))
            }
          }
        ]}
        columns={columns}
      />
    </>
  )
}