import React from 'react';

import { Menu } from '..';

export const MenuContext = React.createContext({
  open: false
});

export function MenuTrigger({
  children,
  ...props
}) {
  const [open, setOpen] = React.useState(false);

  const close = () => {
    setOpen(false)
  }

  const handleEntering = () => { 
  }
 
  return (
    <MenuContext.Provider value={{ open, setOpen }}>
      {children[0]}
      <Menu
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        {...props}
      >
        {children[1](close)}
      </Menu>
    </MenuContext.Provider>
  )
}